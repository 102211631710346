import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import SectionLoader from "../../../helpers/SectionLoader";

import Basic from "../screens/Basic";
import Syllabus from "../screens/Syllabus";
import Enrollments from "../screens/Enrollments";
import Assessments from "../screens/Assessments";
// import Dashboardtop from "../screens/Dashobardtop";

function DigitalHeader() {
	const [activeTab, setActiveTab] = useState(1);

	const [loading, setLoading] = useState(false);

	const TabContent1 = () => <Basic />;
	const TabContent2 = () => <Syllabus />;
	const TabContent3 = () => <Enrollments />;
	const TabContent4 = () => <Assessments />;

	return (
		<>
			<MainContainer>
				<TableContainer>
					<TabHeading
						active={activeTab === 1}
						onClick={() => setActiveTab(1)}
					>
						Basic Info
					</TabHeading>
					<TabHeading
						active={activeTab === 2}
						onClick={() => setActiveTab(2)}
					>
						Syllabus
					</TabHeading>
					<TabHeading
						active={activeTab === 3}
						onClick={() => setActiveTab(3)}
					>
						Enrollment
					</TabHeading>
					<TabHeading
						active={activeTab === 4}
						onClick={() => setActiveTab(4)}
					>
						Assessment
					</TabHeading>
				</TableContainer>
				{loading ? (
					<SectionLoader />
				) : activeTab === 1 ? (
					<TabContent1 />
				) : activeTab === 2 ? (
					<TabContent2 />
				) : activeTab === 3 ? (
					<TabContent3 />
				) : (
					<TabContent4 />
				)}
			</MainContainer>
		</>
	);
}

export default DigitalHeader;

const MainContainer = styled.div`
	width: 100%;
	color: white;
`;

const TabHeading = styled.h3`
	font-size: 14px;
	margin-right: 18px;
	padding-bottom: 4px;
	font-family: "gordita_medium";

	color: ${(props) => (props.active ? "#fe673a" : "#667085")};
	border-bottom: ${(props) => (props.active ? "2px solid #fe673a" : "none")};
	cursor: pointer;
`;
const TableContainer = styled.div`
	display: flex;
	border-bottom: 1px solid #26272a;
	margin-bottom: 28px;
`;
