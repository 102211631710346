import React from "react";
import styled from "styled-components";
import DoughnutChart from "./DoughnutChart";
import LineChart from "./LineChart";
import { Link } from "react-router-dom";

function ChartsContainer({
	enrolledStudents,
	examCompleted,
	courseCompleted,
	onProgress,
	studentArr,
	dateArr,
	campus_id,
}) {
	const items = [
		{
			id: 1,
			count: courseCompleted,
			title: "Videos Completed",
			name: "course",
			campus_link: `/tefun/students?campus_id=${campus_id}&status=videos_completed`,
			link: `/tefun/students?status=videos_completed`,
		},
		{
			id: 2,
			count: examCompleted,
			title: "Exam Completed",
			name: "exam",
			campus_link: `/tefun/students?campus_id=${campus_id}&status=exam_completed`,
			link: `/tefun/students?status=exam_completed`,
		},
		{
			id: 3,
			count: onProgress,
			title: "On Progress",
			name: "progress",
			campus_link: `/tefun/students?campus_id=${campus_id}&status=started`,
			link: `/tefun/students?status=started`,
		},
	];
	return (
		<MainContainer>
			<Cover>
				<LineChartContainer>
					<Top>
						<Heading>Admissions</Heading>
						{/* <DateCard>
                            <Date> 2022</Date>

                            <Icon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/filter.svg"
                                alt="Image"
                                alt=""
                                width={15}
                            />
                        </DateCard> */}
					</Top>
					<LineCard>
						<LineChart studentArr={studentArr} dateArr={dateArr} />
					</LineCard>
				</LineChartContainer>
				<BarChartContainer>
					<Heading>Students Status</Heading>
					<Card>
						<TopSection>
							<Title>Enrolled Students</Title>
							<TotalCount
								to={
									campus_id
										? `/tefun/students?campus_id=${campus_id}`
										: `/tefun/students`
								}
							>
								{enrolledStudents}
							</TotalCount>
						</TopSection>
						<BottomSection>
							<LeftSection>
								{items.map((item, index) => (
									<Section
										key={item.id}
										to={
											campus_id
												? item.campus_link
												: item.link
										}
									>
										<Circle type={item.name}></Circle>
										<CountSection>
											<Count>{item.count}</Count>
											<Para>{item.title}</Para>
										</CountSection>
									</Section>
								))}
							</LeftSection>
							{enrolledStudents > 0 && (
								<RightSection>
									<DoughnutChart
										enrolledStudents={enrolledStudents}
										onProgress={onProgress}
										examCompleted={examCompleted}
										courseCompleted={courseCompleted}
									/>
								</RightSection>
							)}
						</BottomSection>
					</Card>
				</BarChartContainer>
				<Container></Container>
			</Cover>
		</MainContainer>
	);
}

export default ChartsContainer;

const MainContainer = styled.div`
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const Cover = styled.div`
	min-width: 1200px;
	margin: 30px 0;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
`;
const LineChartContainer = styled.div`
	width: 55%;
`;
const BarChartContainer = styled.div`
	width: 30%;
`;
const Title = styled.p`
	color: #fff;
	font-size: 16px;
`;
const TotalCount = styled(Link)`
	color: #fff;
	font-size: 16px;
	font-family: "gordita_medium";
`;
const BottomSection = styled.div`
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const LeftSection = styled.div`
	width: 50%;
`;
const Heading = styled.h6`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 18px;
`;
const LineCard = styled.div`
	border: 1px solid #2c2c2c;
	border-radius: 5px;
	background-color: #1b1c1f;
	padding: 10px 15px 10px 10px;
`;
const Card = styled.div`
	border: 1px solid #2c2c2c;
	border-radius: 5px;
	background-color: #1b1c1f;
	margin-top: 12px;
	padding: 20px 20px 20px 25px;
`;
const TopSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 10px;
	border-bottom: 1px solid #4e5053;
`;
const Section = styled(Link)`
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	:last-child {
		margin-bottom: 0;
	}
`;
const Circle = styled.span`
	height: 12px;
	width: 12px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 8px;
	background: ${(props) =>
		props.type === "course"
			? "#6a7ce7"
			: props.type === "exam"
			? "#ef8637"
			: "#404255"};
`;
const CountSection = styled.div``;
const Count = styled.p`
	color: #fff;
	margin-bottom: 10px;
	font-family: "gordita_medium";
	font-size: 14px;
`;
const Para = styled.p`
	color: #4e5053;
	font-size: 12px;
`;
const RightSection = styled.div`
	width: 50%;
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	justify-content: space-between;
`;
const DateCard = styled.div`
	color: #fff;
	border: 1px solid #2c2c2c;
	border-radius: 5px;
	background-color: #1b1c1f;
	padding: 5px 10px;
	display: flex;
	align-items: baseline;
`;
const Date = styled.p`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 12px;
`;
const Icon = styled.img`
	margin-left: 10px;
`;
const Container = styled.div`
	width: 12%;
`;
