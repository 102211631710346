// First we need to import axios.js
import axios from "axios";

// #### LIVE SERVER CONFIGS #### //

// export const yiaaiConfig = axios.create({
//     baseURL: "https://api.yia.ai/api/v1/",
// });

// #### DEVELOPER SERVER CONFIGS #### //

export const yiaaiConfig = axios.create({
    baseURL: "https://developers-yiaai.talrop.com/api/v1",
});

// #### DEVELOPER LOCAL SERVER CONFIGS #### //

// export const yiaaiConfig = axios.create({
//     baseURL: "http://192.168.0.181:8009/api/v1",
// });
