import React from "react";
import styled from "styled-components";

const ToggleButton = ({ isMalayalam, setMalayalam }) => {
	//   const dispatch = useDispatch();

	const handleChange = (e) => {
		if (e.target.checked) {
			setMalayalam(true);
		} else {
			setMalayalam(false);
		}
	};
	return (
		<Container>
			<CheckBoxWrapper>
				<SubTitle>English</SubTitle>
				<CheckBox
					id="checkbox"
					type="checkbox"
					onChange={(e) => handleChange(e)}
				/>
				<CheckBoxLabel htmlFor="checkbox" />
				<SecondTitle>മലയാളം</SecondTitle>
			</CheckBoxWrapper>
		</Container>
	);
};

const Container = styled.div`
	margin-right: 14px;
	cursor: pointer;
`;

const CheckBoxWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: end;
	align-items: center;
`;
const CheckBoxLabel = styled.label`
	position: absolute;
	top: 0;
	right: 56px;
	width: 46px;
	height: 22px;
	border-radius: 26px;
	background: #fe673a;
	/* border: 1px solid #fff; */
	cursor: pointer;
	&::after {
		content: "";
		display: block;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		margin: 1px;
		background: #fff;
		transition: 0.2s;
	}
	@media all and (max-width: 360px) {
		right: 68px;
	}
`;
const CheckBox = styled.input`
	cursor: pointer;
	opacity: 0;
	z-index: 1;
	border-radius: 15px;
	width: 42px;
	height: 24px;

	&:checked + ${CheckBoxLabel} {
		&::after {
			content: "";
			display: block;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			margin-left: 25px;
			transition: 0.2s;
		}
	}
`;
const SubTitle = styled.h3`
	color: #ffff;
	font-size: 14px;
	margin-right: 17px;
	font-family: "gordita_medium";
	@media all and (max-width: 480px) {
		font-size: 13px;
	}
	@media all and (max-width: 360px) {
		font-size: 12px;
	}
`;
const SecondTitle = styled.h3`
	color: #ffff;
	font-size: 14px;
	font-family: "gordita_medium";
	@media all and (max-width: 480px) {
		font-size: 13px;
	}
	@media all and (max-width: 360px) {
		font-size: 12px;
	}
`;
export default ToggleButton;
