import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";

import { yiaaiConfig } from "../../../../axiosConfig";
import VideoPlayer from "../../../../components/applications/video-player/src/VideoPlayer";
import Blocks from "editorjs-blocks-react-renderer";
import { Link, useParams, useSearchParams } from "react-router-dom";
// import { learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
// import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import NoData from "../jobdesk/NoData";

function ProjectOverView({
	setModal,
	// details,
	editorContent,
	isbuttonLoad,
	setIsButtonLoad,
}) {
	const { id } = useParams();
	const [topicOverview, setTopicOverview] = useState("");
	const [isLoading, setLoading] = useState(false);

	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	// const handlePublished = async (details_status) => {
	// 	setIsButtonLoad(true);
	// 	const Newstatus = details_status;
	// 	setIsToggled(Newstatus === "published" ? true : false);
	// 	const formData = new FormData();
	// 	formData.append(
	// 		"status",
	// 		details_status === "published" ? "draft" : "published"
	// 	);

	// 	try {
	// 		const response = await learnConfig.post(
	// 			`projects/publish/${slug}/`,
	// 			formData,
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${access_token}`,
	// 				},
	// 			}
	// 		);

	// 		const { StatusCode, data } = response.data;

	// 		if (StatusCode === 6000) {
	// 			// setReloadApi((prev) => !prev);
	// 			setIsButtonLoad(false);
	// 		} else {
	// 			console.log(data);
	// 		}
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	const fetchTopicOverview = () => {
		setLoading(true);
		yiaaiConfig
			.get(`free-courses/topic/${id}`, {
				params: {
					// page: currentPage,
					// query: searchterm,
				},

				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setTopicOverview(data);

					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchTopicOverview();
	}, []);

	const details = [
		{
			cover_video_url: topicOverview.video_url,
			image: topicOverview.thumbnail,
			editorContent: "",
		},
	];

	const videoJsOptions = {
		autoplay: false,
		controls: false,
		sources: [
			{
				src: details?.cover_video_url,
				type: "video/m3u8",
			},
		],
	};

	return (
		<MainContainer id="main">
			<Container>
				<Heading>{topicOverview.name}</Heading>
				<SubHead>
					Dashboard / {topicOverview.course_name} /{" "}
					{topicOverview.lesson_name} / {topicOverview.name}
				</SubHead>
				<BottomContainer>
					<HeadingBottom>Topic Overview</HeadingBottom>
					<BottonLeft>
						<CardContainer>
							<InnerCard>
								<ImageContainer>
									<Logo
										src={
											require("../../../../assets/icons/filter-lines.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainer>
								<Description>
									{/* {isCountLoading ? (
										<ButtonLoader />
									) : ( */}
									{/* )} */}
									<h3>Order No :</h3>
									{""} <span>{topicOverview.order_id}</span>
								</Description>
							</InnerCard>
							<InnerCard>
								<ImageContainer>
									<Logo
										src={
											require("../../../../assets/icons/time.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainer>
								<Description>
									{/* {isCountLoading ? (
										<ButtonLoader />
									) : ( */}
									<span>{topicOverview.duration}</span>
									{/* )} */}

									{/* {isCountLoading ? (
										<ButtonLoader />
									) : ( */}

									{/* )} */}
								</Description>
							</InnerCard>
							<InnerCard>
								<ImageContainer>
									<Logo
										src={
											require("../../../../assets/icons/publish-tick.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainer>
								<Description>
									{topicOverview.is_publish === true ? (
										<h3>Published</h3>
									) : (
										<h3>Not Published</h3>
									)}
								</Description>
							</InnerCard>
						</CardContainer>
						<BottomVideoContainer>
							<div>
								<VideoHead>Topic Thumbnail</VideoHead>
								<VideoSection>
									<VideoPlayer
										cover={details?.image}
										source={
											topicOverview.video_url
												? topicOverview.video_url
												: null
										}
										{...videoJsOptions}
										autoPlay={false}
									/>

									<LazyLoadImage
										src={details?.image}
										effect="blur"
									/>
								</VideoSection>
								<ThumbnailHead>Topic Thumbnail</ThumbnailHead>
								<ThumbnailImageContainer>
									<ThumbNailImage
										src={topicOverview.thumbnail}
										alt="thumb"
									/>
								</ThumbnailImageContainer>
								<DescriptionHead>Description</DescriptionHead>
								<TextSection>
									{topicOverview.description}
								</TextSection>
							</div>
						</BottomVideoContainer>
					</BottonLeft>
				</BottomContainer>
			</Container>
		</MainContainer>
	);
}

export default ProjectOverView;
const BottomVideoContainer = styled.div`
	/* display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 70%; */
	margin: 0 auto;
	padding: 0 200px;
	width: 100%;
`;

const VideoHead = styled.h5`
	color: #fff;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 10px;
`;
const ThumbnailHead = styled.h5`
	color: #fff;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 10px;
`;
const ThumbnailImageContainer = styled.div`
	width: 30%;
	margin-bottom: 20px;
`;
const ThumbNailImage = styled.img`
	display: block;
	width: 100%;
`;
const DescriptionHead = styled.h5`
	color: #fff;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 10px;
`;
const MainContainer = styled.div``;
const Container = styled.div``;
const HeadContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	border: 1px solid #2c2c2c;
	padding: 16px;
	border-radius: 4px;
	background-color: #1b1c1f;
	margin-bottom: 20px;
`;
const HeadLeft = styled.div``;
const EditButton = styled.div`
	cursor: pointer !important;
	background-color: rgb(27, 28, 31);
	border: 1px solid rgb(254, 103, 58);
	display: flex;
	align-items: center;
	padding: 6px 14px;
	border-radius: 6px;
	&.disabled {
		opacity: 0.2;
		cursor: not-allowed !important;
	}
`;
const EditIcon = styled.span`
	display: inline-block;
	width: 16px;
	margin-right: 6px;
	img {
		display: block;
		width: 100%;
	}
`;
const EditText = styled(Link)`
	font-family: "gordita_medium";
	font-size: 14px;
	color: #fff;
	&.disabled {
		cursor: not-allowed !important;
	}
`;
const HeadRight = styled.div`
	display: flex;
	align-items: center;
`;
const TextContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
const AssignButton = styled.div`
	margin-left: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 6px;
	border-radius: 8px;
	width: 160px;
	height: 45px;
	background-color: #fe673a;
	cursor: pointer;
`;
const AssignIcon = styled.span`
	display: inline-block;
	width: 15px;
	margin-right: 10px;
	img {
		display: block;
		width: 100%;
	}
`;
const Heading = styled.h3`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 24px;
	margin-bottom: 10px;
`;
const HeadingBottom = styled.h3`
	font-family: "gordita_medium";
	font-size: 20px;
	color: #ffff;
	margin-bottom: 12px;
`;

const SubHead = styled.h5`
	color: #b7b7b7;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 24px;
`;
const AssignText = styled.h5`
	font-family: "gordita_medium";
	font-size: 15px;
	color: #fff;
`;
const BottomContainer = styled.div`
	width: 100%;
`;

const LeftText = styled.h4`
	color: #9c9c9c;
	height: 20px;
	font-family: "gordita_medium";
	font-size: 16px;
`;

const RightText = styled.h4`
	color: #9c9c9c;
	height: 20px;
	font-family: "gordita_medium";
	font-size: 16px;
`;

const ToggleContainer = styled.div`
	height: 33px;
`;

const ToggleButton = styled.button`
	width: 40px;
	height: 22px;
	border: none;
	border-radius: 12px;
	background-color: ${({ toggled }) => (toggled ? "#fe673a" : "#2c2c2c")};
	cursor: pointer;
	position: relative;

	&::before {
		content: "";
		width: ${({ toggled }) => (toggled ? "19px" : "16px")};
		height: ${({ toggled }) => (toggled ? "19px" : "16px")};
		border-radius: 50%;
		background-color: #fff;
		position: absolute;
		top: ${({ toggled }) => (toggled ? "1px" : "3px")};
		left: ${({ toggled }) => (toggled ? "calc(100% - 22px)" : "2px")};
		transition: left 0.3s ease-in-out;
	}
`;

const BottonLeft = styled.div`
	width: 100%;
	padding: 24px;
	box-shadow: 0px 1px 2px 0px #1018280d;
	border: 1px solid #252525;
	border-radius: 12px;
`;
const BottomRight = styled.div`
	width: 30%;
`;

const VideoSection = styled.div`
	margin-bottom: 40px;
	width: 80%;

	img {
		display: block;
		width: 100%;
	}
`;
const TextSection = styled.div`
	margin-top: 10px;
	color: #fff;
	font-size: 12px;
	max-width: 750px;

	font-family: "gordita_regular";
`;

const CardContainer = styled.ul`
	display: flex;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid #2c2c2c;
	padding: 20px;
	margin-bottom: 20px;
	font-family: "gordita_regular";
	:hover {
		opacity: 0.85;
		cursor: ${({ type }) =>
			type === "not_active" ? "not-allowed" : "pointer"};
	}
`;
const InnerCard = styled.div`
	align-items: center;
	display: flex;
`;
// const Card = styled(Link)`
// 	background-color: #1b1c21;
// 	border: 1px solid #2c2c2c;
// 	border-radius: 5px;
// 	padding: 15px;
// 	font-family: "gordita_regular";
// 	:hover {
// 		opacity: 0.85;
// 		cursor: ${({ type }) =>
// 			type === "not_active" ? "not-allowed" : "pointer"};
// 	}
// `;

const ImageContainer = styled.span`
	margin-right: 10px;
	width: 25px;
`;

const Logo = styled.img`
	width: 100%;
	display: block;
`;

const Description = styled.div`
	color: #fff;
	display: flex;
	align-items: center;
	h3 {
		color: #e4e4e4;
		font-size: 16px;
		font-family: "gordita_medium";
	}
	span {
		color: #e4e4e4;
		font-size: 18px;
		font-family: "gordita_medium";
		margin-right: 5px;
	}
`;
