import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { yiaaiConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../helpers/SectionLoader";
import { format } from "date-fns";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import queryString from "query-string";
import YiaaiAdminHelmet from "../../helpers/YiaaiAdminHelmet";
import { Link, useParams, useSearchParams } from "react-router-dom";

import Avatar from "../../../assets/icons/Avatar.svg";
import FilterModal from "../../includes/modals/FilterModal";
import CampusCard from "../../includes/yiaai/CampusCard";
import USersCard from "./UsersCard";
import FilterModalUser from "../../includes/modals/FilterModalUser";

function Users({ campusCount }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isLoading, setLoading] = useState(false);
	const [campusData, setCampusData] = useState([]);
	const [id, setId] = useState([]);
	const [isCountLoading, setIsCountLoading] = useState("");
	const [campusModal, setCampusModal] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({});
	const [searchterm, setSearchTerm] = useState("");
	const [users, setUsers] = useState([]);
	const [searchShow, setSearchShow] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const currentParams = Object.fromEntries([...searchParams]);
	const [isFilter, setIsFilter] = useState(false);
	const [UserDetails, setUserDetails] = useState("");
	// const [showModal, setShowModal] = useState(false);
	const [filters, setFilters] = useState({
		startDate: null,
		onProgress: false,
		completed: false,
	});
	console.log(showModal, "-showModal--");
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);
	const location = useLocation({ isCountLoading });
	let { search } = location;
	const values = queryString.parse(search);
	let page = values.page;
	const { slug } = useParams();
	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleApplyFilter = (startDate, onProgress, completed) => {
		setFilters({ startDate, onProgress, completed });
		setShowModal(false);
	};

	const handlePageClick = ({ selected: selectedPage }) => {
		const newPage = selectedPage + 1;
		setCurrentPage(newPage);
		setSearchParams({
			...currentParams,
			page: newPage.toString(),
		});
	};
	console.log(filters.startDate, "5ttt");
	useEffect(() => {
		const setInitialSearch = () => {
			setCurrentPage(page ? parseInt(page) : 1);
		};
		if (page) {
			setInitialSearch();
		}
	}, [page]);

	useEffect(() => {
		const fetchEntrollmentDetails = () => {
			setLoading(true);
			yiaaiConfig
				.get(`campuses/users/`, {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
					// params: {
					// 	page: page,
					// 	q: searchterm,
					// },
				})
				.then((response) => {
					let { StatusCode, paginator_data, data } = response.data;
					if (StatusCode === 6000) {
						setUserDetails(data);
						setPagination(paginator_data);
					}
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchEntrollmentDetails();
	}, [access_token, page, searchterm, slug]);

	const fetchEntrollments = () => {
		setLoading(true);

		const formattedStartDate = filters.startDate
			? format(new Date(filters.startDate), "yyyy-MM-dd")
			: null;

		yiaaiConfig
			.get(`free-courses/users/`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
				params: {
					page: page,
					q: searchterm,
					date: formattedStartDate,
					// is_completed: filters.completed
					// 	? true
					// 	: filters.onProgress
					// 	? false
					// 	: null,
				},
			})
			.then((response) => {
				let { StatusCode, paginator_data, data } = response.data;
				if (StatusCode === 6000) {
					setUsers(data);
					setPagination(paginator_data);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};
	useEffect(() => {
		fetchEntrollments();
	}, [access_token, page, searchterm, slug, filters]);
	const name = searchParams.get("name");

	return (
		<>
			<YiaaiAdminHelmet title="Lessons" />
			<MainContainer id="main">
				<Container>
					<HeadingTop>Users</HeadingTop>
					<SubHead>Dashboard / Users</SubHead>
					<TopContainer>
						<Card>
							<LabelContainer>
								<ImageContainerSmall>
									<Logo
										src={
											require("../../../assets/icons/students.svg")
												.default
										}
										alt="students"
									/>
								</ImageContainerSmall>
								<Description>
									<h3>Total Users</h3>
								</Description>
							</LabelContainer>
							<Count>{UserDetails.total_students}</Count>
						</Card>

						<Card>
							<LabelContainer>
								<ImageContainerSmall>
									<Logo
										src={
											require("../../../assets/icons/completed-students.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainerSmall>
								<Description>
									<h3>Completed Users</h3>
								</Description>
							</LabelContainer>
							<Count>{UserDetails.completed_students}</Count>
						</Card>

						<Card>
							<LabelContainer>
								<ImageContainerSmall>
									<Logo
										src={
											require("../../../assets/icons/incomplete-students.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainerSmall>
								<Description>
									<h3>Incomplete Users</h3>
								</Description>
							</LabelContainer>
							<Count>{UserDetails.incompleted_students}</Count>
						</Card>

						<Card>
							<LabelContainer>
								<ImageContainerSmall>
									<Logo
										src={
											require("../../../assets/icons/active-students.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainerSmall>
								<Description>
									<h3>Active Users</h3>
								</Description>
							</LabelContainer>
							<Count>{UserDetails.active_users}</Count>
						</Card>
					</TopContainer>
					<BoxContainer>
						<Cover>
							<Head>
								<Heading>Users list</Heading>
							</Head>
							<CoverContainer>
								<InputContainer>
									<SearchName
										type="text"
										placeholder="Search Here"
										onChange={(e) => {
											setSearchTerm(e.target.value);
										}}
										value={searchterm}
									/>
									<SearchImage
										to={`?query=${searchterm}`}
										// onClick={() =>
										//     setSearchParams({
										//         ...currentParams,
										//         q: toParams(searchterm),
										//     })
										// }
									>
										<img
											src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg"
											alt="image"
										/>
									</SearchImage>
								</InputContainer>
								{/* <FilterDiv>
									<ButtonDiv
										onClick={() => setIsFilter(!isFilter)}
									>
										<span>Filter</span>
										<ButtonIcon>
											<img
												src={
													require("../../../assets/icons/filter-lines.svg")
														.default
												}
											/>
										</ButtonIcon>
									</ButtonDiv>
									{isFilter === true && (
										<FilterModalUser
											showModal={isFilter}
											setShowModal={setIsFilter}
										/>
									)}
								</FilterDiv> */}
								<FilterDiv>
									<ButtonDiv
										onClick={() => {
											setShowModal(!showModal);
										}}
									>
										<span>Filter</span>
										<ButtonIcon>
											<img
												src={
													require("../../../assets/icons/filter-lines.svg")
														.default
												}
											/>
										</ButtonIcon>
									</ButtonDiv>
									{showModal && (
										<FilterModalUser
											showModal={showModal}
											setShowModal={setShowModal}
											onClose={handleCloseModal}
											onApply={handleApplyFilter}
										/>
									)}
								</FilterDiv>
							</CoverContainer>
						</Cover>
						<TableCover>
							<TableHeading>
								<Items>
									<Label type="slno">Order No.</Label>
									<Label type="profile">Profile</Label>
									<Label type="date">Joining Date</Label>
									<Label type="reset"></Label>
									<Label type="view"></Label>
								</Items>
							</TableHeading>
							{isLoading ? (
								<SectionLoader />
							) : (
								<>
									{users.length > 0 ? (
										<>
											<UserCardCover>
												{users.map((item, index) => (
													<USersCard
														key={index}
														index={index}
														user={item}
														pagination={pagination}
													/>
												))}
											</UserCardCover>
										</>
									) : (
										<ImageBox>
											<CardImage
												src={
													"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/12-02-2022/emptycard.svg"
												}
												alt="Image"
											/>
										</ImageBox>
									)}
								</>
							)}
						</TableCover>
					</BoxContainer>

					{pagination && pagination.total_pages > 1 && (
						<PaginationContainer>
							<PaginationText>
								Showing lessons {pagination.first_item} -{" "}
								{pagination.last_item} of{" "}
								{pagination.total_items}
							</PaginationText>
							<ReactPaginate
								previousLabel={"< Prev"}
								nextLabel={"Next >"}
								pageCount={pagination.total_pages}
								onPageChange={handlePageClick}
								containerClassName={"pagination"}
								previousLinkClassName={"pagination__link"}
								nextLinkClassName={"pagination__link"}
								disabledClassName={"pagination__link--disabled"}
								activeClassName={"pagination__link--active"}
								forcePage={page - 1} // Ensure correct initial page
							/>
						</PaginationContainer>
					)}
				</Container>
			</MainContainer>
		</>
	);
}

export default Users;

const MainContainer = styled.div`
	color: #fff;
`;
const Container = styled.div`
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const BoxContainer = styled.div``;

const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 15px;
`;
const Heading = styled.h3`
	font-family: "gordita_medium";
	font-size: 20px;
`;
const TableCover = styled.div`
	background-color: #1b1c1f;
	padding: 20px 20px 0;
	border-radius: 5px;
	overflow-x: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
`;
const FilterDiv = styled.div`
	position: relative;
`;
const TableHeading = styled.div`
	min-width: 1200px;
`;
const Items = styled.ul`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
`;
const Label = styled.li`
	width: 10%;
	text-align: center;
	color: #b1b1b1;
	font-family: "gordita_medium";
	width: ${(props) =>
		props.type === "slno"
			? "9%"
			: props.type === "profile"
			? "60%"
			: props.type === "date"
			? "26%"
			: props.type === "reset"
			? "12%"
			: props.type === "view"
			? "5%"
			: "10%"};

	text-align: ${(props) =>
		props.type === "action"
			? "right"
			: props.type === "count" || props.type === "date"
			? "center"
			: props.type === "slno" || props.type === "name"
			? "left"
			: "left"};
`;
const UserCardCover = styled.div`
	padding: 20px 0 0;
`;
const CoverContainer = styled.div`
	margin-bottom: 15px;
	width: 36%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const InputContainer = styled.div`
	border-radius: 5px;
	background: #161619;
	width: 100%;
	height: 40px;
	margin-right: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #272729;
	padding: 0 10px;
`;
const SearchImage = styled(Link)`
	width: 5%;
	object-fit: contain;
	margin-right: 5px;
	img {
		width: 100%;
		display: block;
	}
`;
const SearchName = styled.input`
	font-size: 14px;
	font-family: "gordita_regular";
	height: 40px;
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #fff;
	::placeholder {
		font-family: "gordita_regular";
		font-size: 14px;
	}
`;
const HeadingTop = styled.h3`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 24px;
	margin-bottom: 10px;
`;

const SubHead = styled.h5`
	color: #b7b7b7;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 26px;
`;
const ImageBox = styled.div`
	margin: 0 auto;
	width: 20%;
	margin-top: 100px;
	height: calc(100% - (-50px));
	@media all and (max-width: 980px) {
		width: 200px;
	}
`;
const CardImage = styled.img`
	width: 100%;
	display: block;
`;
const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 0 15px;
	@media all and (max-width: 890px) {
		flex-direction: column;
	}
`;
const PaginationText = styled.p`
	color: #898989;
	font-size: 14px;
	@media all and (max-width: 890px) {
		margin-bottom: 10px;
	}
`;
const Cover = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ButtonDiv = styled.div`
	padding: 0 6px;
	position: relative;
	font-family: "gordita_regular";
	height: 40px;
	border-radius: 5px;
	color: #cf5633;
	border: 1px solid #cf5633;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 20px;
	span {
		font-size: 14px;
		margin-right: 8px;
	}
`;
const ButtonIcon = styled.div`
	width: 20px;
	margin-right: 10px;
	img {
		width: 100%;
		display: block;
	}
`;
const TopContainer = styled.div`
	display: grid;
	grid-template-columns: ${({ id }) =>
		id ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr "};
	grid-gap: 20px;
	margin-bottom: 30px;
`;
const Card = styled.div`
	background-color: #1b1c21;
	border: 1px solid #2c2c2c;
	border-radius: 5px;
	padding: 15px;
	font-family: "gordita_regular";
	:hover {
		opacity: 0.85;
		cursor: ${({ type }) =>
			type === "not_active" ? "not-allowed" : "pointer"};
	}
`;
const LabelContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;
const Count = styled.h5`
	color: #ffffff;
	font-size: 26px;
	font-weight: 600;
	margin-right: 10px;
`;
const Logo = styled.img`
	width: 100%;
	display: block;
`;

const Description = styled.div`
	color: #fff;
	display: flex;
	align-items: center;
	h3 {
		color: #e4e4e4;
		font-size: 16px;
		font-family: "gordita_medium";
	}
	span {
		color: #e4e4e4;
		font-size: 18px;
		font-family: "gordita_medium";
		margin-right: 5px;
	}
`;
const ImageContainerSmall = styled.div`
	width: 20px;
	margin-right: 10px;
`;
