import React from 'react';
import styled from 'styled-components'

const RouterLoading = () => {
  return <Cover>
      
  </Cover>;
};

export default RouterLoading;
const Cover=styled.div`
    background: #141414;
    height: 100vh;
`
