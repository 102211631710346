import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function ActiveSessionCard({
    isLoading,
    allPlatformUsers,
    webUsers,
    androidUsers,
    iosUsers,
    activatedDevices,
    androidPhone,
    iosPhone,
    tablet,
    desktop,
    id,
}) {
    return (
        <>
            <Heading>Active Session</Heading>
            <BottomContainer>
                <PlatformUsersContainer>
                    <Cover>
                        <Left
                            to={
                                id
                                    ? `/tefun/students/?campus_id=${id}&platform=all`
                                    : "/tefun/students/?platform=all"
                            }
                        >
                            <h3>Platform Users</h3>
                            {isLoading ? (
                                <ButtonLoader />
                            ) : (
                                <p>{allPlatformUsers}</p>
                            )}
                        </Left>
                        <Right>
                            <ChromePlatformCard
                                to={
                                    id
                                        ? `/tefun/students/?campus_id=${id}&platform=web`
                                        : "/tefun/students/?platform=web"
                                }
                            >
                                <LogoContainer>
                                    <Logo
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/12-02-2022/chrome.svg"
                                        }
                                    />
                                </LogoContainer>
                                <Details>
                                    {isLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        <span>{webUsers}</span>
                                    )}
                                    <p>Web User</p>
                                </Details>
                            </ChromePlatformCard>
                            <ChromePlatformCard
                                to={
                                    id
                                        ? `/tefun/students/?campus_id=${id}&platform=android_app`
                                        : "/tefun/students/?platform=android_app"
                                }
                            >
                                <LogoContainer>
                                    <Logo
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/12-02-2022/android.svg"
                                        }
                                    />
                                </LogoContainer>
                                <Details>
                                    {isLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        <span>{androidUsers}</span>
                                    )}
                                    <p>Android</p>
                                </Details>
                            </ChromePlatformCard>
                            <ChromePlatformCard
                                to={
                                    id
                                        ? `/tefun/students/?campus_id=${id}&platform=ios_app`
                                        : "/tefun/students/?platform=ios_app"
                                }
                            >
                                <LogoContainer>
                                    <Logo
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/12-02-2022/apple.svg"
                                        }
                                    />
                                </LogoContainer>
                                <Details>
                                    {isLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        <span>{iosUsers}</span>
                                    )}
                                    <p>IOS</p>
                                </Details>
                            </ChromePlatformCard>
                        </Right>
                    </Cover>
                </PlatformUsersContainer>
                <ActivateDevicesContainer>
                    <Cover>
                        <LeftContainer
                            to={
                                id
                                    ? `/tefun/students/?campus_id=${id}&platform=active`
                                    : "/tefun/students/?platform=active"
                            }
                        >
                            <h3>Active Devices</h3>
                            <p>{activatedDevices}</p>
                        </LeftContainer>
                        <RightContainer>
                            <DeviceCard
                                to={
                                    id
                                        ? `/tefun/students/?campus_id=${id}&platform=android_phone`
                                        : "/tefun/students/?platform=android_phone"
                                }
                            >
                                <DeviceLogo>
                                    <Logo
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/12-02-2022/android-phone.svg"
                                        }
                                    />
                                </DeviceLogo>
                                <Details>
                                    {isLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        <span>{androidPhone}</span>
                                    )}
                                    <p>Android Phone</p>
                                </Details>
                            </DeviceCard>
                            <DeviceCard
                                to={
                                    id
                                        ? `/tefun/students/?campus_id=${id}&platform=ios_phone`
                                        : "/tefun/students/?platform=ios_phone"
                                }
                            >
                                <DeviceLogo>
                                    <Logo
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/12-02-2022/apple-phone.svg"
                                        }
                                    />
                                </DeviceLogo>
                                <Details>
                                    {isLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        <span>{iosPhone}</span>
                                    )}
                                    <p>Iphone</p>
                                </Details>
                            </DeviceCard>
                            <DeviceCard
                                to={
                                    id
                                        ? `/tefun/students/?campus_id=${id}&platform=tablet`
                                        : "/tefun/students/?platform=tablet"
                                }
                            >
                                <TabletLogo>
                                    <Logo
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/12-02-2022/tablet.svg"
                                        }
                                    />
                                </TabletLogo>
                                <Details>
                                    {isLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        <span>{tablet}</span>
                                    )}
                                    <p>Tablet</p>
                                </Details>
                            </DeviceCard>
                            <DeviceCard
                                to={
                                    id
                                        ? `/tefun/students/?campus_id=${id}&platform=desktop`
                                        : "/tefun/students/?platform=desktop"
                                }
                            >
                                <DesktopLogo>
                                    <Logo
                                        src={
                                            require("../../../assets/images/desktop.svg")
                                                .default
                                        }
                                    />
                                </DesktopLogo>
                                <Details>
                                    {isLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        <span>{desktop}</span>
                                    )}
                                    <p>Desktop</p>
                                </Details>
                            </DeviceCard>
                            <DeviceCard
                                to={
                                    id
                                        ? `/tefun/students/?campus_id=${id}&platform=desktop`
                                        : ""
                                }
                            >
                                <DesktopLogo>
                                    <Logo
                                        src={
                                            require("../../../assets/images/others.svg")
                                                .default
                                        }
                                    />
                                </DesktopLogo>
                                <Details>
                                    {isLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        <span>--</span>
                                    )}
                                    <p>Others</p>
                                </Details>
                            </DeviceCard>
                        </RightContainer>
                    </Cover>
                </ActivateDevicesContainer>
            </BottomContainer>
        </>
    );
}

export default ActiveSessionCard;

const Heading = styled.h3`
    color: #fff;
    padding-bottom: 10px;
    font-family: "gordita_medium";
    font-size: 18px;
`;
const Cover = styled.div`
    min-width: 1100px;
    width: 100%;
    border: 1px solid #2c2c2c;
    border-radius: 5px;
    background-color: #1b1c1f;
    padding: 20px 20px 20px 25px;
    color: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const TopContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    overflow-x: scroll;
    margin-bottom: 30px;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Card = styled(Link)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #1b1c21;
    border: 1px solid #2c2c2c;
    border-radius: 5px;
    padding: 30px;
    font-family: "gordita_regular";
    :hover {
        opacity: 0.85;
        cursor: pointer;
    }
`;
const ImageContainer = styled.span`
    margin-right: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Logo = styled.img`
    width: 100%;
    display: block;
`;
const Description = styled.div`
    color: #fff;

    p {
        color: #fff;
        font-size: 22px;
        font-family: "gordita_medium";
    }
    h3 {
        font-size: 12px;
    }
`;
const BottomContainer = styled.div`
    /* overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    min-width: 980px; */
`;
const PlatformUsersContainer = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Left = styled(Link)`
    h3 {
        font-size: 14px;
        color: #fff;
    }
    p {
        color: #fff;
        font-size: 26px;
        font-family: "gordita_medium";
    }
`;
const Right = styled.div`
    width: 55%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
`;
const ChromePlatformCard = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #161619;
    border: 1px solid #2c2c2c;
    border-radius: 5px;
    padding: 15px;
`;
const LogoContainer = styled.div`
    width: 40px;
    margin-bottom: 15px;
`;
const Details = styled.span`
    width: 100% !important;
    p {
        color: #fff;
        font-size: 14px !important;
    }
    span {
        color: #fff;
        font-size: 22px !important;
        font-family: "gordita_medium";
    }
`;
const ActivateDevicesContainer = styled.div`
    /* width: 100%;
    border: 1px solid #2c2c2c;
    border-radius: 5px;
    background-color: #1b1c1f;
    padding: 20px 20px 20px 25px;
    color: #fff;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between; */
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const LeftContainer = styled(Link)`
    h3 {
        font-size: 14px;
        color: #fff;
    }
    p {
        color: #fff;
        font-size: 26px;
        font-family: "gordita_medium";
    }
`;
const DeviceCard = styled(Link)`
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #161619;
    border: 1px solid #2c2c2c;
    border-radius: 5px;
`;

const DeviceLogo = styled.div`
    width: 25px;
    margin-bottom: 15px;
`;
const TabletLogo = styled.div`
    width: 50px;
    margin-bottom: 15px;
`;
const DesktopLogo = styled.div`
    margin-bottom: 15px;
    width: 50px;
`;
const RightContainer = styled.div`
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
`;
