
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { yiaaiConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import ChartsContainer from "../../../includes/yiaai/ChartsContainer";
import { getShortMonthName } from "../../../helpers/functions";

import ActiveSessionCard from "../../../includes/yiaai/ActiveSessionCard";
import YiaaiAdminHelmet from "../../../helpers/YiaaiAdminHelmet";
import Dashboardtop from "./Dashobardtop";

function DashBoard() {
	const [totalCount, setTotalCount] = useState({});
	const [userData, setUserData] = useState({});
	const [isLoading, setLoading] = useState(false);
	const [isCountLoading, setCountLoading] = useState(false);
	const [userDetails, setUserDetails] = useState([]);
	const [lineGraphData, setLineGraphData] = useState({});
	const [dateArr, setDateArr] = useState([]);
	const [studentArr, setStudentArr] = useState([]);
	// const
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	useEffect(() => {
		const fetchDetails = () => {
			setCountLoading(true);
			setLoading(true);
			yiaaiConfig
				.get("campuses/total-students-count/", {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				})
				.then((response) => {
					let { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setTotalCount(data);
						console.log(data);
						setLoading(false);
						setCountLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					setCountLoading(false);
				});
		};
		fetchDetails();
	}, []);

	useEffect(() => {
		const fetchUserDetails = () => {
			setLoading(true);
			yiaaiConfig
				.get("accounts/platform-students-count/", {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				})
				.then((response) => {
					let { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setUserData(data);
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchUserDetails();
	}, []);

	useEffect(() => {
		const fetchDetails = () => {
			setLoading(true);
			yiaaiConfig
				.get("campuses/admissions-day-chart/", {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				})
				.then((response) => {
					let { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setUserDetails(data);
						setLineGraphData(data.user_data);

						let newArr = data.user_data.reduce(
							(prev, next) => prev.concat(next.date),
							[]
						);

						newArr = newArr.map((item, index) => {
							const month = getShortMonthName(new Date(item));
							const day = item.slice(item.length - 2);

							return `${day} ${month}`;
						});

						const newStudentsArr = data.user_data.reduce(
							(prev, next) => prev.concat(next.students),
							[]
						);

						setDateArr(newArr);
						setStudentArr(newStudentsArr);
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchDetails();
	}, []);

	return (
		<>
			<YiaaiAdminHelmet title="Dashboard" />
			<MainContainer id="main">
				<Cover>
					<Dashboardtop 
						isCountLoading={isCountLoading}
						totalCount={totalCount}
						campusCount={totalCount.associated_campuses}
						totalStudentsCount={totalCount.total_students}
						enrolledStudents={totalCount.enrolled_students}
						notEnrolleStudents={totalCount.not_enrolled_students}
						courseCompleted={totalCount.course_completed}
					/>
					<ChartsContainer
						enrolledStudents={totalCount.enrolled_students}
						onProgress={totalCount.on_progress}
						examCompleted={totalCount.exam_completed}
						courseCompleted={totalCount.course_completed}
						studentArr={studentArr}
						dateArr={dateArr}
					/>
					<ActiveSessionCard
						isLoading={isLoading}
						allPlatformUsers={userData.platform_users}
						webUsers={userData.web}
						androidUsers={userData.android}
						iosUsers={userData.ios}
						activatedDevices={userData.activated_devices}
						androidPhone={userData.android_phone}
						iosPhone={userData.ios_phone}
						tablet={userData.tablet}
						desktop={userData.desktop}
					/>
				</Cover>
			</MainContainer>
		</>
	);
}

export default DashBoard;

const MainContainer = styled.div`
	width: 100%;
`;
const Cover = styled.div`
	padding: 0 12px;
`;

