import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { yiaaiConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";

function AddLessonModal({
	fetchSyllabus,
	mode,
	setAddModal,
	addModal,
	addNewClass,
	lessonName,
	setLessonName,
	orderNumber,
	setOrderNumber,
	lesson,
	setLesson,
	isActive,
	setIsActive,
	id,
}) {
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	const handleLessonNameChange = (e) => {
		e.preventDefault();
		setLessonName(e.target.value);
	};

	const handleOrderNumberChange = (e) => {
		e.preventDefault();
		setOrderNumber(e.target.value);
	};

	const handleToggle = (e) => {
		e.preventDefault();
		setIsActive(!isActive);
	};

	const handleSave = () => {
		addNewClass({
			name: lessonName,
			order_id: orderNumber,
			is_publish: isActive,
		});
		setLessonName("");
		setOrderNumber("");
		setAddModal(false);
		fetchSyllabus();
	};

	return (
		<BackContainer style={{ transform: addModal && "scale(1,1)" }}>
			<Overlay
				onClick={() => setAddModal(false)}
				style={{ transform: addModal && "scale(1,1)" }}
			></Overlay>
			<Modal
				onClick={(e) => e.stopPropagation()}
				data-effect="mfp-zoom-in"
			>
				<Conatiner>
					<HeadingContainer>
						<Heading>
							{mode === "edit" ? "Edit Lesson" : "Add New Lesson"}
						</Heading>
						<Close>
							<ButtonClose
								onClick={() => {
									setAddModal(false);
									setLessonName("");
									setOrderNumber("");
									setAddModal(false);
								}}
							>
								<img
									src={
										require("../../../assets/images/close.svg")
											.default
									}
								/>
							</ButtonClose>
						</Close>
					</HeadingContainer>
					<InputsContainer>
						<InputWrapper>
							<Label>Lesson Name</Label>
							<Input
								type="text"
								value={lessonName}
								onChange={handleLessonNameChange}
								placeholder="Enter Lesson Name"
							/>
						</InputWrapper>
						<InputWrapper>
							<Label>Order Number</Label>
							<BottomToggle>
								<InputOrder
									type="number"
									value={orderNumber}
									placeholder="Enter Order Number"
									onChange={handleOrderNumberChange}
								/>

								<ToggleWrapper>
									<ToggleContainer onClick={handleToggle}>
										<ToggleButton isActive={isActive} />
									</ToggleContainer>
									<Label>Publish Now</Label>
								</ToggleWrapper>
							</BottomToggle>
						</InputWrapper>
					</InputsContainer>
					<BottomContainer>
						<Button onClick={handleSave}>
							{mode === "edit" ? "Save" : "Add"}
						</Button>
					</BottomContainer>
				</Conatiner>
			</Modal>
		</BackContainer>
	);
}

export default AddLessonModal;

// Styled components
const BottomToggle = styled.div`
	display: flex;
	align-items: center;
`;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.5s;
	transform: scale(0, 0);
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
`;

const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
	transform: scale(0);
`;

const ToggleContainer = styled.div`
	display: inline-block;
	cursor: pointer;
	margin-right: 10px;
`;

const InputOrder = styled.input`
	width: 44%;
	height: 50px;
	border: 1px solid #3b3b3b;
	border-radius: 5px;
	padding: 0 10px;
	margin-right: 20px;
	background: #161619;
	color: #fff;
`;

const ToggleButton = styled.div`
	width: 30px;
	height: 17px;
	background-color: ${(props) => (props.isActive ? "#FE673A" : "#ccc")};
	border-radius: 15px;
	position: relative;
	transition: background-color 0.3s ease;

	&::after {
		content: "";
		width: 16px;
		height: 16px;
		background-color: white;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: ${(props) => (props.isActive ? "14px" : "0")};
		transition: left 0.3s ease;
	}
`;

const Modal = styled.div`
	width: 550px;
	max-width: 550px;
	height: ${({ textarea }) => (textarea ? "90vh" : "")};
	margin: 0 auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	transition: 0.5s;
	z-index: 101;
	border: 1px solid #3b3b3b;
	background: #1b1c1f;
	border-radius: 5px;
	box-shadow: 0px 3px 56px #00000048;

	@media all and (max-width: 980px) {
		width: 550px;
	}
	@media all and (max-width: 768px) {
		width: 400px;
	}
	@media all and (max-width: 640px) {
		width: 400px;
	}
	@media all and (max-width: 480px) {
		width: 340px;
	}
	@media all and (max-width: 360px) {
		width: 300px;
	}
`;

const Conatiner = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
	height: 100%;
	padding-bottom: 50px;

	@media all and (max-width: 640px) {
		padding: 28px;
	}
`;

const HeadingContainer = styled.div`
	border-bottom: 1px solid #3b3b3b;
	padding-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Heading = styled.h3`
	font-family: "gordita_medium";
	font-size: 22px;
	color: #fff;
`;

const Close = styled.div`
	cursor: pointer;
`;

const ButtonClose = styled.div`
	width: 20px;
	img {
		display: block;
		width: 100%;
	}
`;

const InputsContainer = styled.div`
	margin-top: 20px;
`;

const InputWrapper = styled.div`
	margin-bottom: 20px;
	text-align: start;
`;

const Label = styled.label`
	color: #c3c3c3;
	font-family: "gordita_medium";
	font-size: 14px;
	margin-bottom: 5px;
	display: block;
`;

const Input = styled.input`
	width: 100%;
	height: 50px;
	border: 1px solid #3b3b3b;
	border-radius: 5px;
	padding: 0 10px;
	background: #161619;
	color: #fff;
`;

const ToggleWrapper = styled.div`
	display: flex;
	align-items: normal;
`;

const BottomContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Button = styled.div`
	width: 16%;
	height: 38px;
	border: 1px solid #fe673a;
	border-radius: 5px;
	display: inline-block;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: #fe673a;
	font-family: "gordita_regular";
	color: #fff;

	@media all and (max-width: 480px) {
		/* width: 40%;
		height: 40px; */
	}
`;
