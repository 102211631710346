import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import styled from "styled-components";
import { faker } from "@faker-js/faker";

function DigitalLineChart({ linchartData }) {
	Chart.register(...registerables);

	const labels = linchartData.map((data) => data.month_name);

	const data = {
		labels: labels,
		datasets: [
			{
				label: "Enrollments",
				data: linchartData.map((data) => data.students),
				backgroundColor: "#FE673A",
				borderColor: "#FE673A",
				tension: 0.5,
			},
		],
	};

	const options = {
		plugins: {
			drawBorder: false,
			title: {
				display: false,
				text: "",
			},
		},
		layout: {
			padding: 20,
		},
		responsive: true,
		scales: {
			x: {
				grid: {
					display: false,
				},
				title: {
					display: true,
					text: "Months",
				},
			},
			y: {
				grid: {
					display: false,
				},
				title: {
					display: true,
					text: "Count",
				},
				ticks: {
					stepSize: 20,
					min: 0,
					max: 100,
					callback: function (value, index, values) {
						return value;
					},
				},
			},
		},
	};

	return (
		<Container>
			<Line data={data} options={options} />
		</Container>
	);
}

export default DigitalLineChart;

const Container = styled.div`
	canvas {
		width: 100% !important;
		margin: 0 auto;
		height: 270px !important;
	}
`;
