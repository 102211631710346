export const secondsTohm = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);

  var hDisplay =
    h > 0 ? h + (h === 1 ? " hr" : " hrs") + (m > 0 ? ", " : "") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min" : " mins") : "";
  return hDisplay + mDisplay;
};

export const calcTime = (hours) => {
  if (hours) {
    let hours_split = Math.floor(hours);
    let min_split = (hours % 1).toFixed(2);
    let minutes = Math.floor(min_split * 60);
    if (hours === 1) {
      return `${hours} Hour`;
    } else if (hours_split === 1) {
      return `${hours_split} Hr ${
        minutes === 0
          ? ""
          : minutes === 1
          ? `${minutes} min`
          : `${minutes} mins`
      }`;
    } else {
      return `${hours_split} Hrs ${
        minutes === 0
          ? ""
          : minutes === 1
          ? `${minutes} min`
          : `${minutes} mins`
      }`;
    }
  } else {
    return "";
  }
};

export const secondsTohms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay =
    h > 0 ? h + (h === 1 ? " hr" : " hrs") + (m > 0 && s > 0 ? ", " : "") : "";
  var mDisplay =
    m > 0 ? m + (m === 1 ? " min" : " mins") + (s > 0 ? ", " : "") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  return hDisplay + mDisplay + sDisplay;
};

export const getDateStr = (value) => {
  let date = new Date(value);
  let month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ][date.getMonth()];
  let date_str = date.getDate() + " " + month + " " + date.getFullYear();
  return date_str;
};

export const getShortMonthName = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[date.getMonth()].substring(0, 3);
};

export const getTimeStr = (value) => {
  if (value) {
    let values = value.split(":");
    let hr = values[0];
    let min = values[1];
    let ampm = values[2].split(" ")[1];
    let time_str = hr + ":" + min + " " + ampm;
    return time_str;
  } else {
    return null;
  }
};

export const getTimeStrFromDate = (value, type = "hm") => {
  let date = new Date(value);
  date = new Date(date.toUTCString());
  let tzOffset = date.getTimezoneOffset();
  date = new Date(date.getTime() - tzOffset * 60 * 1000);

  return tConvert(date.toLocaleTimeString(), type);
};

export const getUserDateFromUTC = (value, type = "hm") => {
  return getDateStr(value);
};

export const getTimeFromDate = (value, type = "hm") => {
  let date = new Date(value);
  return tConvert(date.toLocaleTimeString(), type);
};

function tConvert(time, type) {
  if (time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      if (type === "hm") {
        time.pop();
      }
      time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    return time.join(""); // return adjusted time or original string
  } else {
    return null;
  }
}

export const findObjectFromArray = (array, key, value) => {
  let result = array.find((item) => {
    return item[key] === value;
  });
  return result;
};

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getLetterColor(letter) {
  var colors = [
    {
      letter: "A",
      color: "#e53935",
    },
    {
      letter: "B",
      color: "#D81B60",
    },
    {
      letter: "C",
      color: "#8E24AA",
    },
    {
      letter: "D",
      color: "#5E35B1",
    },
    {
      letter: "E",
      color: "#3949AB",
    },
    {
      letter: "F",
      color: "#1E88E5",
    },
    {
      letter: "G",
      color: "#039BE5",
    },
    {
      letter: "H",
      color: "#00ACC1",
    },
    {
      letter: "I",
      color: "#00897B",
    },
    {
      letter: "J",
      color: "#43A047",
    },
    {
      letter: "K",
      color: "#7CB342",
    },
    {
      letter: "L",
      color: "#C0CA33",
    },
    {
      letter: "M",
      color: "#FDD835",
    },
    {
      letter: "N",
      color: "#FFB300",
    },
    {
      letter: "O",
      color: "#FB8C00",
    },
    {
      letter: "P",
      color: "#F4511E",
    },
    {
      letter: "Q",
      color: "#6D4C41",
    },
    {
      letter: "R",
      color: "#757575",
    },
    {
      letter: "S",
      color: "#607D8B",
    },
    {
      letter: "T",
      color: "#E64A19",
    },
    {
      letter: "U",
      color: "#F57C00",
    },
    {
      letter: "V",
      color: "#FFA000",
    },
    {
      letter: "W",
      color: "#FBC02D",
    },
    {
      letter: "X",
      color: "#AFB42B",
    },
    {
      letter: "Y",
      color: "#689F38",
    },
    {
      letter: "Z",
      color: "#303F9F",
    },
    {
      letter: "a",
      color: "#e53935",
    },
    {
      letter: "b",
      color: "#D81B60",
    },
    {
      letter: "c",
      color: "#8E24AA",
    },
    {
      letter: "d",
      color: "#5E35B1",
    },
    {
      letter: "e",
      color: "#3949AB",
    },
    {
      letter: "f",
      color: "#1E88E5",
    },
    {
      letter: "g",
      color: "#039BE5",
    },
    {
      letter: "h",
      color: "#00ACC1",
    },
    {
      letter: "i",
      color: "#00897B",
    },
    {
      letter: "j",
      color: "#43A047",
    },
    {
      letter: "k",
      color: "#7CB342",
    },
    {
      letter: "l",
      color: "#C0CA33",
    },
    {
      letter: "m",
      color: "#FDD835",
    },
    {
      letter: "n",
      color: "#FFB300",
    },
    {
      letter: "o",
      color: "#FB8C00",
    },
    {
      letter: "p",
      color: "#F4511E",
    },
    {
      letter: "q",
      color: "#6D4C41",
    },
    {
      letter: "r",
      color: "#757575",
    },
    {
      letter: "s",
      color: "#607D8B",
    },
    {
      letter: "t",
      color: "#E64A19",
    },
    {
      letter: "u",
      color: "#F57C00",
    },
    {
      letter: "v",
      color: "#FFA000",
    },
    {
      letter: "w",
      color: "#FBC02D",
    },
    {
      letter: "x",
      color: "#AFB42B",
    },
    {
      letter: "y",
      color: "#689F38",
    },
    {
      letter: "z",
      color: "#303F9F",
    },
  ];
  let color = findObjectFromArray(colors, "letter", letter);
  return color ? color.color : "#8BC34A";
}

export const truncateString = (string, maxLength = 50) => {
  if (!string) return null;
  if (string.length <= maxLength) return string;
  return `${string.substring(0, maxLength)}...`;
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getDateDifference(date) {
  let current_date = new Date();
  let end_date = new Date(date);

  let end_date_UTC = new Date(
    Date.UTC(
      end_date.getUTCFullYear(),
      end_date.getUTCMonth(),
      end_date.getUTCDate()
    )
  );

  let current_date_UTC = new Date(
    Date.UTC(
      current_date.getUTCFullYear(),
      current_date.getUTCMonth(),
      current_date.getUTCDate()
    )
  );

  let yAppendix = 0,
    mAppendix = 0,
    dAppendix = 0;
  let days = end_date_UTC.getDate() - current_date_UTC.getDate();
  if (days < 0) {
    end_date_UTC.setMonth(end_date_UTC.getMonth() - 1);
    days += DaysInMonth(end_date_UTC);
  }

  let months = end_date_UTC.getMonth() - current_date_UTC.getMonth();
  if (months < 0) {
    end_date_UTC.setFullYear(end_date_UTC.getFullYear() - 1);
    months += 12;
  }

  let years = end_date_UTC.getFullYear() - current_date_UTC.getFullYear();

  if (years > 1) yAppendix = " years";
  else yAppendix = " year";
  if (months > 1) mAppendix = " months";
  else mAppendix = " month";
  if (days > 1) dAppendix = " days";
  else dAppendix = " day";

  return `${years ? `${years}${yAppendix}, ` : ""}${
    months ? `${months}${mAppendix}, ` : ""
  }${days ? `${days}${dAppendix}` : "Today"}`;
}

export function DaysInMonth(end_date_UTC) {
  let monthStart = new Date(
    end_date_UTC.getFullYear(),
    end_date_UTC.getMonth(),
    1
  );
  let monthEnd = new Date(
    end_date_UTC.getFullYear(),
    end_date_UTC.getMonth() + 1,
    1
  );
  let monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
  return monthLength;
}

export const findSuperScript = (date) => {
  let new_date = getDateStr(date).slice(0, 2);
  if (new_date > 3 && new_date < 21) return "th";
  switch (new_date % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
const addDays = function (str, days) {
  const myDate = new Date(str);
  myDate.setDate(myDate.getDate() + parseInt(days));
  return myDate.toISOString();
};
export const getYYMMDD = (value) => {
  if (value) {
    const event = new Date(value);
    const iso = event.toISOString();
    const d = addDays(iso, 1);
    const date = d.substring(0, 10);
    return date;
  }
  return "";
};
export const getDDMMYY = (value) => {
  if (value) {
    var today = new Date(value);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + "-" + mm + "-" + yyyy;
    return today;
  }
  return "";
};

export const toParams = (value) => {
  return value.replace(" ", "_").toLowerCase();
};

export const nextAlphabet = (value) => {
  const str = value;
  const nextLetter = String.fromCharCode(str.charCodeAt(str.length - 1) + 1);
  return nextLetter;
};
