import React from "react";
import { Helmet } from "react-helmet";

const YiaaiAdminHelmet = ({ title }) => {
    if (title) {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${title}`} | Yiaai Admin </title>
                <meta
                    id="og-title"
                    property="og:title"
                    content={`${title} | Yiaai Admin`}
                />
            </Helmet>
        );
    } else {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Yiaai Admin | An OTT community platform powered by Talrop.
                </title>
                <meta
                    id="og-title"
                    property="og:title"
                    content="Yiaai | An OTT community platform powered by Talrop."
                />
            </Helmet>
        );
    }
};

export default YiaaiAdminHelmet;
