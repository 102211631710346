import React, { useState, useEffect, useContext } from "react";
import { yiaaiConfig } from "../../axiosConfig";
import styled from "styled-components";
import "../../assets/css/style.css";
import { Context } from "../contexts/Store";
import "../../assets/css/style.css";
import { useNavigate } from "react-router-dom";

function AddCollege() {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const navigate = useNavigate();

    // ---------(addschool form details) -------- //

    const [campusName, setCampusName] = useState("");
    const [pincode, setPincode] = useState("");
    const [email, setEmail] = useState("");
    const [personName, setPersonName] = useState("");
    const [phone, setPhone] = useState("");
    const [logo, setLogo] = useState("");
    const [institutionType, setInstitutionType] = useState(2);
    const [location, setLocation] = useState(4);
    const [code, setCode] = useState("");
    const [logoName, setLogoName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // ---------(list of district) -------- //

    const [district, setDistrict] = useState([]);

    //collage code error message timeout-------
    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("");
        }, 5000);
    }, [errorMessage]);

    // ---------(addcollage form submitting) -------- //

    const submitForm = (e) => {
        if (e) {
            e.preventDefault();
        }
        const formData = new FormData();

        // if (logo) {
        formData.append("name", campusName);
        formData.append("pincode", pincode);
        formData.append("email", email);
        formData.append("person_name", personName);
        formData.append("phone", phone);
        formData.append("logo", logo);
        formData.append("location", location);
        formData.append("code", code);
        formData.append("institution_type", institutionType);
        // }
        yiaaiConfig
            .post("/campuses/create/campus/", formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    navigate("/campuses");
                    setCampusName("");
                    setPincode("");
                    setEmail("");
                    setPersonName("");
                    setPhone("");
                    setLocation("");
                    setLogo("");
                    setCode("");
                } else if (StatusCode === 6001) {
                    setErrorMessage(data.message);
                }
                else {
                    alert("error occured");
                    console.log("Error");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // ---------(list of district) -------- //

    useEffect(() => {
        yiaaiConfig
            .get("campuses/district/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setDistrict(data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // ---------(enter logo) -------- //

    const handleLogo = (e) => {
        if (e.target.name === "logo") {
            if (e.target.files.length > 0) {
                setLogo(e.target.files[0]);
                setLogoName(e.target.files[0].name);
            }
        }
    };

    return (
        <>
            <MainContainer>
                <Container className="wrapper">
                    <TopContainer>
                        <Title> Add College </Title>
                    </TopContainer>
                    <FormContainer>
                        <Form>
                            <InputContainer className="school">
                                <label>Name of the College</label>
                                <Input
                                    className="school"
                                    type="text"
                                    placeholder="Enter your Campus name"
                                    required
                                    onChange={(e) =>
                                        setCampusName(e.target.value)
                                    }
                                    value={campusName}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>Location</label>
                                <select
                                    value={location}
                                    onChange={(e) =>
                                        setLocation(e.target.value)
                                    }
                                >
                                    {district.map((dist) => (
                                        <option value={dist.id}>
                                            {dist.name}
                                        </option>
                                    ))}
                                </select>
                            </InputContainer>
                            <InputContainer>
                                <label>Pincode</label>
                                <Input
                                    type="text"
                                    placeholder="Enter the Pincode"
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>Email</label>
                                <Input
                                    type="email"
                                    placeholder="Enter Your Email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>Name of the contact person</label>
                                <Input
                                    type="text"
                                    placeholder="Enter the Name of the contact person"
                                    required
                                    value={personName}
                                    onChange={(e) =>
                                        setPersonName(e.target.value)
                                    }
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>Contact number</label>
                                <Input
                                    type="text"
                                    placeholder="Enter the Contact number"
                                    required
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>College logo</label>
                                <Input
                                    placeholder="Upload document"
                                    type="file"
                                    id="schoolLogo"
                                    name="logo"
                                    accept="image/*"
                                    onChange={(e) => {
                                        handleLogo(e);
                                    }}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>College code</label>
                                <Input
                                    type="text"
                                    placeholder="Enter the College code"
                                    required
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                                <ErrorMsg>{errorMessage}</ErrorMsg>
                            </InputContainer>
                        </Form>
                    </FormContainer>
                    <BottomContainer>
                        <Button
                            onClick={() => {
                                navigate("/campuses");
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                if (campusName === "") {
                                    alert("Enter Campus Name");
                                } else if (pincode === "") {
                                    alert("Enter Pincode");
                                } else if (email === "") {
                                    alert("Enter your email");
                                } else if (personName === "") {
                                    alert("Enter your Name");
                                } else if (phone === "") {
                                    alert("Enter your phone number");
                                } else if (logo === "") {
                                    alert("Enter your collage logo");
                                } else if (code === "") {
                                    alert("Enter your collage code");
                                } else {
                                    submitForm();
                                }
                                // submitForm()
                            }}
                        >
                            Save
                        </Button>
                    </BottomContainer>
                </Container>
            </MainContainer>
        </>
    );
}

export default AddCollege;
const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    padding: 8% 0;
`;
const Container = styled.div`
    color: #fff;
    background-color: #1b1c1f;
    width: 100%;
    padding: 40px;
    &.wrapper {
        width: 60%;
        margin: 0 auto;
    }
`;
const TopContainer = styled.div`
    border-bottom: 1px solid #333333;
    margin-bottom: 50px;
`;
const Title = styled.div`
    margin-bottom: 20px;
    font-family: "gordita_medium";
`;
const FormContainer = styled.div``;
const Form = styled.form`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    label {
        font-size: 12px;
        color: #fffc;
        font-family: gordita_regular;
        position: absolute;
        left: 0px;
        top: -30px;
        &.location {
            top: 65px;
            left: 170px;
            font-size: 10px;
            color: #9a9898;
        }
        a {
            color: #fe673a;
        }
    }
`;
const Input = styled.input`
    width: 100%;
    color: #fff;
    &.school {
        width: 100%;
    }
    &::-webkit-file-upload-button {
        display: none;
    }
`;

const ErrorMsg = styled.h6`
    color: #cf5633;
    font-size: 16px;
    font-size: 12px;
    position: absolute;
    top: 65px;
    left: 0px;
`;

const InputContainer = styled.div`
    border: 1px solid #333333;
    background: #161619;
    display: flex;
    padding: 15px;
    min-height: 55px;
    cursor: text;
    max-height: 55px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 50px;
    width: 48%;
    &.school {
        width: 100%;
    }
    select {
        width: 500px;
        color: #fff;
    }
    option {
        background-color: #161619;
    }
`;
const BottomContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px 0;
    @media all and (max-width: 480px) {
        justify-content: center;
    }
`;
const Button = styled.button`
    width: 20%;
    height: 50px;
    border: 1px solid #fe673a;
    border-radius: 5px;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: "gordita_regular";
    color: #fff;
    margin-right: 20px;
    :hover {
        background: #fe673a;
        color: #fff;
    }
    @media all and (max-width: 480px) {
        width: 20%;
        height: 40px;
    }
`;
const ClassDiv = styled.div``;
const TickContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px;
`;
const Span = styled.span`
    margin-right: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
`;
const InputDiv = styled.div`
    height: 25px;
    width: 25px;
    border: 1px solid #3b3b3b;
    margin-right: 5px;
    border-radius: 5px;
    // cursor: pointer;
    background: #161619;
    cursor: ${({ is_added }) => (is_added ? "not-allowed" : "pointer")};
    & div {
        display: none;

        img {
            width: 100%;
            display: block;
        }
    }
    & div.selected {
        display: block;
    }
`;
const Middle = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 60%;
`;
const DropDown = styled.div`
    img {
        width: 100%;
        display: block;
    }
`;
