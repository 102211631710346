import React, { useState, useContext, useEffect } from "react";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import styled from "styled-components";
import { yiaaiConfig } from "../../../../axiosConfig";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Header from "../includes/Header";
import { Context } from "../../../contexts/Store";

function Dashboardtop() {
	const [isLoading, setLoading] = useState(false);
	const [course, setCourse] = useState("");

	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	const { slug } = useParams();
	useEffect(() => {
		const fetchEntrollments = () => {
			setLoading(true);
			yiaaiConfig
				.get(`free-courses/course/${slug}/`, {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				})
				.then((response) => {
					let { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setCourse(data);
					}
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchEntrollments();
	}, [access_token, slug]);
	return (
		<MainContainer id="main">
			<Container>
				<Heading>{course.name}</Heading>
				<SubHead>Dashboard / {course.name}</SubHead>
				<Header />
			</Container>
		</MainContainer>
	);
}

export default Dashboardtop;

const MainContainer = styled.div`
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const Container = styled.div`
	padding: 10px 0 0 10px;
`;

const Heading = styled.h3`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 24px;
	margin-bottom: 10px;
`;

const SubHead = styled.h5`
	color: #b7b7b7;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 26px;
`;
