import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { yiaaiConfig } from "../../axiosConfig";
import styled from "styled-components";
import "../../assets/css/style.css";
import { Context } from "../contexts/Store";
import { getShortMonthName } from "../helpers/functions";
import { get } from "jquery";

function AddSchool() {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const navigate = useNavigate();

    // ---------(selected class) -------- //

    const [classId, setClassId] = useState(0);

    // ---------(addschool form details) -------- //

    const [campusName, setCampusName] = useState("");
    const [pincode, setPincode] = useState("");
    const [email, setEmail] = useState("");
    const [personName, setPersonName] = useState("");
    const [phone, setPhone] = useState("");
    const [logo, setLogo] = useState("");
    const [location, setLocation] = useState(4);
    const [code, setCode] = useState("");
    const [logoName, setLogoName] = useState("");
    const [data, setData] = useState([]);
    const [listed, setListed] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    // const [type, setType] = useState([]);

    // ---------(list of district) -------- //

    const [district, setDistrict] = useState([]);

    // useEffect(() => {
    //     const campusClasses = data.reduce(
    //         (prev, next) => prev.concat(next.campus_classes),
    //         []
    //     );
    //     const selectedClasses = campusClasses.filter((e) => e.is_selected);
    //     const selectedClassesIds = selectedClasses.reduce(
    //         (prev, next) => prev.concat(next.id),
    //         []
    //     );
    //     setClassCategoryIds(selectedClassesIds);

    //     const sectionIds = selectedClasses.reduce(
    //         (prev, next) => prev.concat(next.campus_section),
    //         []
    //     );

    //     setClassList([...new Set(sectionIds)]);
    // }, [isClassSelect]);

    // ---------(list of district) -------- //

    useEffect(() => {
        yiaaiConfig
            .get("campuses/district/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setDistrict(data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        const getClasses = () => {
            yiaaiConfig.get("campuses/campus-sections/").then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setData(data);
                }
            });
        };
        getClasses();
    }, []);
    // console.log(listed);
    //  ---------( Setting Id)  -------- //

    // const settingId = (id) => {
    //     setClassId(id);
    //     if (classList.includes(id)) {
    //         let newList = classList.filter((item) => {
    //             console.log("its working");
    //             return item.id !== id;
    //         });
    //         setClassList(newList);
    //     } else {
    //         setClassList([...classList, id]);
    //     }
    // };

    // ---------(enter logo) -------- //

    const handleLogo = (e) => {
        if (e.target.name === "logo") {
            if (e.target.files.length > 0) {
                setLogo(e.target.files[0]);
                setLogoName(e.target.files[0].name);
            }
        }
    };

    // ---------(addschool form submitting) -------- //

    const submitForm = (e) => {
        if (e) {
            e.preventDefault();
        }
        const formData = new FormData();

        if (logo) {
            
            formData.append("name", campusName);
            formData.append("pincode", pincode);
            formData.append("email", email);
            formData.append("person_name", personName);
            formData.append("phone", phone);
            formData.append("logo", logo);
            formData.append("location", location);
            formData.append("code", code);
            formData.append("institution_type", 10);
            for(let i = 0; i < listed.length;i++){
                formData.append("course_names", listed[i]);

            }
            // formData.append("Course", listed);
        }
        yiaaiConfig
            .post("/campuses/create/campus/", formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    navigate("/campuses");
                    setCampusName("");
                    setPincode("");
                    setEmail("");
                    setPersonName("");
                    setPhone("");
                    setLocation("");
                    setCode("");
                    setLogo("");
                    setListed([]);
                }  else if (StatusCode  === 6001) {
                    setErrorMessage(data.message);
                }
                else {
                    alert("Fill all required fields");
                    console.log("Error");
                }
            })
            .catch((error) => {
                alert(error);
            });
            
    };

    //school code error message timeout
    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("");
        }, 5000);
    }, [errorMessage]);

    const showFunc = () => {
        // console.log("name", campusName);
        // console.log("listed", listed);
        // console.log("pincode", pincode);
        // console.log("email", email);
        // console.log("person_name", personName);
        // console.log("phone", phone);
        // console.log("logo", logo);
        // console.log("location", location);
        // console.log("code", code);
        // console.log("institution_type", 10);
        // console.log("course_names", listed);
    };

    const onChange = (event) => {
        //console.log(event.target.checked);
        const isChecked = event.target.checked;
        if (isChecked) {
            setListed([...listed, event.target.value]);
        } else {
            let index = listed.indexOf(event.target.value);
            listed.splice(index, 1);
            setListed(listed);
        }
    };

    return (
        <>
            <MainContainer>
                <Container className="wrapper">
                    <TopContainer>
                        <Title> Add School </Title>
                    </TopContainer>
                    <FormContainer>
                        <Form>
                            <InputContainer className="school">
                                <label>Name of the school</label>
                                <Input
                                    className="school"
                                    type="text"
                                    placeholder="Enter your school"
                                    required
                                    onChange={(e) =>
                                        setCampusName(e.target.value)
                                    }
                                    value={campusName}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>Location</label>
                                <select
                                    value={location}
                                    onChange={(e) =>
                                        setLocation(e.target.value)
                                    }
                                >
                                    {district.map((dist) => (
                                        <option value={dist.id}>
                                            {dist.name}
                                        </option>
                                    ))}
                                </select>
                            </InputContainer>
                            <InputContainer>
                                <label>Pincode</label>
                                <Input
                                    type="text"
                                    placeholder="Enter the Pincode"
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>Email</label>
                                <Input
                                    type="email"
                                    placeholder="Enter Your Email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>Name of the contact person</label>
                                <Input
                                    type="text"
                                    placeholder="Enter the Name of the contact person"
                                    required
                                    value={personName}
                                    onChange={(e) =>
                                        setPersonName(e.target.value)
                                    }
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>Contact number</label>
                                <Input
                                    type="text"
                                    placeholder="Enter the Contact number"
                                    required
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>School logo</label>
                                <Input
                                    placeholder="Upload document"
                                    type="file"
                                    id="schoolLogo"
                                    name="logo"
                                    accept="image/*"
                                    onChange={(e) => {
                                        handleLogo(e);
                                    }}
                                />
                            </InputContainer>
                            <InputContainer>
                                <label>School code</label>
                                <Input
                                    type="text"
                                    placeholder="Enter the School code"
                                    required
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            <ErrorMsg>{errorMessage}</ErrorMsg>
                            </InputContainer>
                        </Form>
                    </FormContainer>
                    <Middle>
                        {data.map((items) => (
                            <ClassDiv>
                                <TickContainer>
                                    <Head>{items.name}</Head>
                                    {items.campus_classes.map((campus) => (
                                        <>
                                            <InputDiv
                                                type="checkbox"
                                                name="classes"
                                                value={campus.name}
                                                onChange={onChange}
                                            />

                                            <Span>
                                                {campus.name}{" "}
                                                <sup>
                                                    {campus.name === "1"
                                                        ? "st"
                                                        : campus.name === "3"
                                                        ? "rd"
                                                        : campus.name === "2"
                                                        ? "nd"
                                                        : "th"}
                                                </sup>
                                            </Span>
                                        </>
                                    ))}
                                </TickContainer>
                            </ClassDiv>
                        ))}
                    </Middle>
                    <BottomContainer>
                        <Button
                            onClick={() => {
                                navigate("/campuses");
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                showFunc();
                                if (campusName === "") {
                                    alert("Enter Name");
                                } else if (pincode === "") {
                                    alert("Enter Pincode");
                                } else if (email === "") {
                                    alert("Enter your email");
                                } else if (personName === "") {
                                    alert("Enter your Name");
                                } else if (phone === "") {
                                    alert("Enter your phone number");
                                } else if (logo === "") {
                                    alert("Enter your school logo");
                                } else if (code === "") {
                                    alert("Enter your school code");
                                } else {
                                    submitForm();
                                }
                                // submitForm()
                            }}
                        >
                            Save
                        </Button>
                    </BottomContainer>
                </Container>
            </MainContainer>
        </>
    );
}
export default AddSchool;
const MainDiv = styled.div``;
const Head = styled.span`
    margin-right: 8px;
`;
const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    padding: 8% 0;
`;

const Container = styled.div`
    color: #fff;
    background-color: #1b1c1f;
    width: 100%;
    padding: 40px;
    &.wrapper {
        width: 60%;
        margin: 0 auto;
    }
`;
const TopContainer = styled.div`
    border-bottom: 1px solid #333333;
    margin-bottom: 50px;
`;
const Title = styled.div`
    margin-bottom: 20px;
    font-family: "gordita_medium";
`;
const FormContainer = styled.div``;
const Form = styled.form`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    label {
        font-size: 12px;
        color: #fffc;
        font-family: gordita_regular;
        position: absolute;
        left: 0px;
        top: -30px;
        &.location {
            top: 65px;
            left: 170px;
            font-size: 10px;
            color: #9a9898;
        }
        a {
            color: #fe673a;
        }
    }
`;
const Input = styled.input`
    width: 100%;
    color: #fff;
    &.school {
        width: 100%;
    }
    &::-webkit-file-upload-button {
        display: none;
    }
`;
const InputContainer = styled.div`
    border: 1px solid #333333;
    background: #161619;
    display: flex;
    padding: 15px;
    min-height: 55px;
    cursor: text;
    max-height: 55px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 50px;
    width: 48%;
    &.school {
        width: 100%;
    }
    select {
        width: 500px;
        color: #fff;
    }
    option {
        background-color: #161619;
    }
`;

const ErrorMsg = styled.h6`
    color: #cf5633;
    font-size: 16px;
    font-size: 12px;
    position: absolute;
    top: 65px;
    left: 0px;
`;

const BottomContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px 0;
    @media all and (max-width: 480px) {
        justify-content: center;
    }
`;
const Button = styled.button`
    width: 20%;
    height: 50px;
    border: 1px solid #fe673a;
    border-radius: 5px;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: "gordita_regular";
    color: #fff;
    margin-right: 20px;
    :hover {
        background: #fe673a;
        color: #fff;
    }
    @media all and (max-width: 480px) {
        width: 20%;
        height: 40px;
    }
`;
const ClassDiv = styled.div``;
const TickContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px;
`;
const Span = styled.span`
    margin-right: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
`;
const InputDiv = styled.input`
    height: 34px;
    width: 18px;
    border: 1px solid #3b3b3b;
    margin-right: 5px;
    border-radius: 5px;
    // cursor: pointer;
    background: #161619;
    cursor: ${({ is_added }) => (is_added ? "not-allowed" : "pointer")};
    background: ${(props) => (props.checked ? "#000" : "transparent")};
`;
const Middle = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 60%;
`;
const DropDown = styled.div`
    img {
        width: 100%;
        display: block;
    }
`;
