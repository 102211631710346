import React, { Suspense, useState, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SectionLoader from "../../helpers/SectionLoader";
import AddCollege from "../../screens/AddCollege";
import AddOtherInstitutions from "../../screens/AddOtherInstitutions";
import AddSchool from "../../screens/AddSchool";
import DigitalMainPage from "../../screens/digitalmarcketing/screens/DigitalMainPage";
import DigitalBasic from "../../screens/digitalmarcketing/screens/Basic";
import Dashboardtop from "../../screens/digitalmarcketing/screens/Dashobardtop";
import DigitalSyllabusCard from "../../includes/yiaai/SyllabusCard";
import DigitalSyllabusTopic from "../../screens/digitalmarcketing/screens/SyllabusTopic";
import TopicSingle from "../../screens/digitalmarcketing/screens/TopicSingle";
import DigitalEntrollmentSingle from "../../screens/digitalmarcketing/screens/EntrollmentSingle";

// import Topic from "../../screens/tefun/screens/Topic";

// import TefunEntrollmentSingle from "../../screens/tefun/screens/TefunEnrollmentSingle";
import Users from "../../screens/users/Users";
import UsersSinglePage from "../../screens/users/UsersSinglePage";
const CampusSingle = lazy(() => import("../../screens/CampusSingle"));
const TefunCampus = lazy(() => import("../../screens/TefunCampus"));
const TefunRegistration = lazy(() => import("../../screens/TefunRegistration"));
const Sidebar = lazy(() => import("../../includes/Sidebar"));
const Topbar = lazy(() => import("../../includes/Topbar"));
const DashBoard = lazy(() => import("../../screens/DashBoard"));
const Tefun = lazy(() => import("../../screens/Tefun"));
const TefunActivity = lazy(() => import("../../screens/TefunActivity"));

const AppRouter = () => {
	const [isExpand, setExpand] = useState(false);

	return (
		<Suspense fallback={<SectionLoader />}>
			<>
				<Sidebar setExpand={setExpand} />
				<Topbar isExpand={isExpand} setExpand={setExpand} />
				<Routes>
					<Route path="/" element={<Navigate to="/dashboard/" />} />
					{/* <Route
						path="/dashboard/tefun/"
						element={<Navigate to="/tefun" />}
					/> */}
					<Route path="addschool/" element={<AddSchool />} />
					<Route path="addcollege/" element={<AddCollege />} />
					<Route
						path="addothers/"
						element={<AddOtherInstitutions />}
					/>
					<Route path="dashboard/" element={<DashBoard />} />
					<Route path="dashboard/:slug" element={<Dashboardtop />} />
					<Route
						path="/syllabus/:id"
						element={<DigitalSyllabusTopic />}
					/>

					<Route
						path="/syllabus/topic/:id"
						element={<TopicSingle />}
					/>
					<Route
						path="/entrollment/:id"
						element={<DigitalEntrollmentSingle />}
					/>
					<Route
						path="digital-and-finance/basic-details/"
						element={<DigitalBasic />}
					/>

					{/* <Route path="tefun/" element={<TefunDashboardtop />} />
					<Route path="tefun/syllabus/:id" element={<Topic />} />
					<Route
						path="tefun/syllabus/topic/:id"
						element={<TefunTopicSingle />}
					/>
					<Route
						path="tefun/entrollment/:id"
						element={<TefunEntrollmentSingle />}
					/> */}
					<Route path="campuses/" element={<TefunCampus />} />
					<Route path="campuses/:id/" element={<CampusSingle />} />
					<Route path="tefun/students/" element={<Tefun />} />
					<Route path="tefun/activity" element={<TefunActivity />} />

					<Route path="users/" element={<Users />} />
					<Route
						path="users/profiles/:id/"
						element={<UsersSinglePage />}
					/>
					<Route
						path="registrations/"
						element={<TefunRegistration />}
					/>
				</Routes>
			</>
		</Suspense>
	);
};

export default AppRouter;
