import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getDateStr } from "../../helpers/functions";
import DeleteModal from "../modals/DeleteModal";
import AddLessonModal from "../modals/AddLessonModal";
import dot from "../../../assets/icons/dot.svg";
import dotGreen from "../../../assets/icons/dot-green.svg";

function DigitalEnrollmentCard({ entrollment, pagination, index, reload }) {
	const [isActive, setIsActive] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [editModal, setEditModal] = useState(false);

	const handleToggleClick = (e) => {
		e.preventDefault();
		setIsActive(!isActive);
	};
	// console.log(entrollment, "--entrollment");
	return (
		<MainContainer>
			<Items to={`/entrollment/${entrollment.id}`}>
				{/* {pagination.first_item < pagination.last_item && ( */}
				<ListItem type="slno">{index + 1}</ListItem>
				{/* )} */}
				<ListItem type="profile">
					<ProfileContainer>
						<AvatarContainer>
							<Avatar src={entrollment.photo} alt="avatar" />
						</AvatarContainer>{" "}
						<div>
							<Name>{entrollment.student_name}</Name>
							<Phone>
								{entrollment.country_code}{" "}
								{entrollment.phone ? entrollment.phone : "---"}
							</Phone>
						</div>
					</ProfileContainer>
				</ListItem>
				{/* <ListItem type="topic">
					{entrollment.phone ? entrollment.phone : "---"}
				</ListItem> */}
				<ListItem type="date">
					{entrollment.date_added ? entrollment.date_added : "---"}
				</ListItem>
				<ListItem type="topic">
					<LessonNumber>
						Lesson{" "}
						{entrollment.current_lesson
							? entrollment.current_lesson
							: "---"}
					</LessonNumber>
					<LessonName>
						{" "}
						{entrollment.current_topic
							? entrollment.current_topic
							: "---"}
					</LessonName>
				</ListItem>
				{/* <ListItem type="topic"></ListItem> */}
				<ListItem type="action">
					<ProgressContainer>
						<DotContainer>
							{/* <DotImage
								src={
									require({entrollment.is_completed===false ?"../../../assets/icons/dot.svg":"../../../assets/icons/dot-green.svg"})
										.default
								}
								alt="dot icon"
							/> */}
							<DotImage
								src={
									entrollment.is_completed === false
										? dot
										: dotGreen
								}
								alt="dot icon"
							/>
						</DotContainer>
						<Progress>
							{entrollment.is_completed === false
								? "In Progress"
								: "Completed"}
						</Progress>
					</ProgressContainer>
				</ListItem>
			</Items>
		</MainContainer>
	);
}

export default DigitalEnrollmentCard;
const ProfileContainer = styled.div`
	display: flex;
	align-items: start;
`;
const ProgressContainer = styled.div`
	display: flex;
	align-items: center;
	background: #f9fafb;
	border: 1px solid #eaecf0;
	padding: 1px 5px;
	border-radius: 8px;
`;
const DotContainer = styled.div`
	width: 8px;
	margin-right: 4px;
`;
const DotImage = styled.img`
	display: block;
	width: 100%;
`;
const Progress = styled.h6`
	color: ${(props) => (props.isCompleted === true ? "#067647" : "#344054")};
	font-family: "gordita_regular";
	font-size: 12px;
`;
const MainContainer = styled.div`
	min-width: 1200px;
`;
const Items = styled(Link)`
	border-top: 1px solid #161619;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 0;
`;
const ListItem = styled.div`
	font-size: 14px;
	align-items: center;
	text-align: start;
	font-family: "gordita_regular";
	color: #fff;
	width: ${(props) =>
		props.type === "slno"
			? "9%"
			: props.type === "profile"
			? "30%"
			: props.type === "date"
			? "25%"
			: props.type === "topic"
			? "20%"
			: props.type === "action"
			? "10%"
			: "10%"};
	display: ${(props) => (props.type === "name" ? "flex" : "")};
	text-align: ${(props) =>
		props.type === "action"
			? "right"
			: props.type === "count" || props.type === "date"
			? "none"
			: ""};
	display: ${(props) => (props.type === "action" ? "flex" : "")};
	justify-content: ${(props) =>
		props.type === "action" ? "space-between" : ""};
	align-items: ${(props) => (props.type === "action" ? "center" : "")};
	span.action {
		padding: 6px 35px;
		border-radius: 5px;
		border: 1px solid #fe673a;
		font-family: "gordita_regular";
	}
`;
const ToggleContainer = styled.div`
	display: inline-block;
	cursor: pointer;
`;

const ToggleButton = styled.div`
	width: 40px;
	height: 25px;
	background-color: ${(props) => (props.isActive ? "#4CAF50" : "#ccc")};
	border-radius: 15px;
	position: relative;
	transition: background-color 0.3s ease;

	&::after {
		content: "";
		width: 25px;
		height: 25px;
		background-color: white;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: ${(props) => (props.isActive ? "14px" : "0")};
		transition: left 0.3s ease;
	}
`;
const IconContainer = styled.div`
	width: 20px;
`;
const Icon = styled.img`
	width: 100%;
	display: block;
`;
const AvatarContainer = styled.div`
	width: 40px;
	margin-right: 6px;
`;
const Avatar = styled.img`
	display: block;
	width: 100%;
	border-radius: 50%;
`;
const Name = styled.h6`
	font-size: 14px;
	font-family: "gordita_medium";
	color: #ffffff;
`;
const Phone = styled.h6`
	font-size: 14px;
	font-family: "gordita_regular";
	color: #ffffff;
`;
const LessonNumber = styled.h6`
	font-size: 14px;
	font-family: "gordita_medium";
	color: #ffffff;
`;
const LessonName = styled.h6`
	font-size: 14px;
	font-family: "gordita_regular";
	color: #ffffff;
`;
const NameiconBox = styled.span`
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "gordita_bold";
	border-radius: 50%;
	font-size: 18px;
	border: 1px solid #fe673a;
	margin-right: 10px;
	min-width: 40px;
`;
const NameSpan = styled.span`
	display: flex;
	transform: translateY(2px);
	color: #fe673a;
`;
const CampusDetails = styled.p`
	white-space: nowrap;
	width: 94%;
	text-overflow: ellipsis;
`;
const CampusName = styled.p`
	text-transform: capitalize;
	font-size: ${(props) => (props.type === "token" ? "10px" : "14px")};
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	/* text-overflow: ellipsis; */
	font-family: "gordita_regular";
	color: ${(props) => (props.type === "token" ? "#fe673a" : "#fff")};
`;
