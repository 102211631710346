import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getDateStr } from "../../helpers/functions";

function CampusCard({ campus, pagination, index }) {
	return (
		<MainContainer>
			<Items to={`${campus.id}`}>
				{pagination.first_item < pagination.last_item && (
					<ListItem type="slno">
						{pagination.first_item + index}
					</ListItem>
				)}
				<ListItem type="name">
					<NameiconBox>
						<NameSpan>{campus.name.charAt(0)}</NameSpan>
					</NameiconBox>
					<CampusDetails>
						<CampusName>{campus.name}</CampusName>
						<CampusName type="token">
							{campus.token_code}
						</CampusName>
					</CampusDetails>
				</ListItem>
				<ListItem>
					{campus.contact_person_phone
						? campus.contact_person_phone
						: "---"}
				</ListItem>
				<ListItem type="count">{campus.total_students}</ListItem>
				<ListItem type="count">{campus.enrolled}</ListItem>
				<ListItem type="count">
					{campus.enrollment_percentage}%
				</ListItem>
				<ListItem type="count">{campus.not_enrolled}</ListItem>
			</Items>
		</MainContainer>
	);
}

export default CampusCard;

const MainContainer = styled.div``;
const Items = styled(Link)`
	border-top: 1px solid #161619;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
`;
const ListItem = styled.p`
	font-size: 14px;
	align-items: center;
	text-align: start;
	font-family: "gordita_regular";
	color: #fff;
	width: ${(props) =>
		props.type === "slno"
			? "5%"
			: props.type === "count"
			? "10%"
			: props.type === "date"
			? "20%"
			: props.type === "name"
			? "30%"
			: "10%"};
	display: ${(props) => (props.type === "name" ? "flex" : "")};
	text-align: ${(props) =>
		props.type === "action"
			? "right"
			: props.type === "count" || props.type === "date"
			? "center"
			: ""};
	display: ${(props) => (props.type === "action" ? "flex" : "")};
	justify-content: ${(props) => (props.type === "action" ? "flex-end" : "")};
	align-items: ${(props) => (props.type === "action" ? "center" : "")};
	span.action {
		padding: 6px 35px;
		border-radius: 5px;
		border: 1px solid #fe673a;
		font-family: "gordita_regular";
	}
`;
const NameiconBox = styled.span`
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "gordita_bold";
	border-radius: 50%;
	font-size: 18px;
	border: 1px solid #fe673a;
	margin-right: 10px;
	min-width: 40px;
`;
const NameSpan = styled.span`
	display: flex;
	transform: translateY(2px);
	color: #fe673a;
`;
const CampusDetails = styled.p`
	white-space: nowrap;
	width: 94%;
	text-overflow: ellipsis;
`;
const CampusName = styled.p`
	text-transform: capitalize;
	font-size: ${(props) => (props.type === "token" ? "10px" : "14px")};
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	/* text-overflow: ellipsis; */
	font-family: "gordita_regular";
	color: ${(props) => (props.type === "token" ? "#fe673a" : "#fff")};
`;
