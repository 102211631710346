import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { yiaaiConfig } from "../../../axiosConfig";
import DeleteModal from "../modals/DeleteModal";
import AddLessonModal from "../modals/AddLessonModal";
import { Context } from "../../contexts/Store";
import PropTypes from "prop-types";

function DigitalSyllabusCard({
	syllabus,
	fetchData,
	slug,
	pagination,
	index,
	setReload,
	reload,
	setId,
}) {
	const [isActive, setIsActive] = useState(syllabus.is_publish);
	const [lessonName, setLessonName] = useState("");
	const [orderNumber, setOrderNumber] = useState("");
	const [deleteModal, setDeleteModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [isClassLoading, setClassLoading] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [lesson, setLesson] = useState("");
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	const addEditClass = () => {
		setClassLoading(true);
		yiaaiConfig
			.post(
				`/free-courses/edit-lesson/${syllabus.id}/`,
				{
					name: lessonName,
					order_id: orderNumber,
					is_publish: isActive,
				},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				const { status_code, data } = response.data;

				if (status_code === 6000) {
					setClassLoading(false);
					setEditModal(false);
					setReload(!reload);
				} else {
				}
			})
			.catch((error) => {
				console.error(error);
				setClassLoading(false);
			});
	};

	const fetchLesson = () => {
		setLoading(true);
		yiaaiConfig
			.get(`free-courses/lesson/${syllabus.id}/`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
				// params: {
				// 	page: currentPage,
				// 	q: searchterm,
				// },
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				console.log(data, "data");
				if (StatusCode === 6000) {
					setLesson(data);
					setLessonName(data?.name);
					setOrderNumber(data?.order_id);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	const deleteLesson = () => {
		setLoading(true);
		console.log("Access Token:", access_token);
		yiaaiConfig
			.post(
				`free-courses/lesson-delete/${syllabus.id}/`,
				{},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
					// params: {
					// 	page: currentPage,
					// 	q: searchterm,
					// },
				}
			)
			.then((response) => {
				let { StatusCode, data } = response.data;
				console.log(data, "data");
				if (StatusCode === 6000) {
					// setLesson(data);
					// setLessonName(data?.name);
					// setOrderNumber(data?.order_id);
					setLoading(false);
					setDeleteModal(false); // Close the delete modal after successful deletion
					setReload(!reload);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	// useEffect(() => {
	// 	fetchLesson();
	// }, [slug]);

	const handleToggleClick = (e) => {
		e.preventDefault();
		setIsActive((prevIsActive) => !prevIsActive);

		yiaaiConfig
			.post(
				`free-courses/lesson-publish/${syllabus.id}/`,
				{
					is_publish: !isActive,
				},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setIsActive(data.is_publish);
				} else {
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<MainContainer>
			<AddLessonModal
				setAddModal={setEditModal}
				addModal={editModal}
				addNewClass={addEditClass}
				id={syllabus.id}
				lessonName={lessonName}
				setLessonName={setLessonName}
				orderNumber={orderNumber}
				setOrderNumber={setOrderNumber}
				isActive={isActive}
				setIsActive={setIsActive}
				mode="edit"
				lesson={lesson}
				setLesson={setLesson}
			/>
			<DeleteModal
				deleteModal={deleteModal}
				setDeleteModal={setDeleteModal}
				deleteApi={deleteLesson}
			/>
			<Items to={`/syllabus/${syllabus.id}`}>
				<ListItem type="slno">{syllabus.order_id}</ListItem>
				<ListItem type="name">{syllabus.name}</ListItem>
				<ListItem type="topic">{syllabus.topic_count}</ListItem>
				<ListItem type="public">
					<ToggleContainer onClick={handleToggleClick}>
						<ToggleButton isActive={isActive} />
					</ToggleContainer>
				</ListItem>
				<ListItem type="action">
					<IconContainer>
						<Icon
							src={
								require("../../../assets/icons/view.svg")
									.default
							}
							alt="view icon"
						/>
					</IconContainer>
					<IconContainer
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setEditModal(true);
							fetchLesson();
						}}
					>
						<Icon
							src={
								require("../../../assets/icons/edit.svg")
									.default
							}
							alt="edit icon"
						/>
					</IconContainer>

					<IconContainer
						onClick={(e) => {
							e.preventDefault();
							setDeleteModal(true);
						}}
					>
						<Icon
							src={
								require("../../../assets/icons/delete.svg")
									.default
							}
							alt="delete icon"
						/>
					</IconContainer>
				</ListItem>
			</Items>
		</MainContainer>
	);
}

DigitalSyllabusCard.propTypes = {
	syllabus: PropTypes.object.isRequired,
	slug: PropTypes.string.isRequired,
	pagination: PropTypes.object,
	index: PropTypes.number,
};

export default DigitalSyllabusCard;

const MainContainer = styled.div`
	min-width: 1200px;
`;

const Items = styled(Link)`
	border-top: 1px solid #161619;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
`;

const ListItem = styled.p`
	font-size: 14px;
	align-items: center;
	text-align: start;
	font-family: "gordita_regular";
	color: #fff;
	width: ${(props) =>
		props.type === "slno"
			? "9%"
			: props.type === "name"
			? "60%"
			: props.type === "topic"
			? "12%"
			: props.type === "public"
			? "12%"
			: props.type === "action"
			? "10%"
			: "10%"};
	display: ${(props) => (props.type === "name" ? "flex" : "")};
	text-align: ${(props) =>
		props.type === "action"
			? "right"
			: props.type === "count" || props.type === "date"
			? "center"
			: ""};
	display: ${(props) => (props.type === "action" ? "flex" : "")};
	justify-content: ${(props) =>
		props.type === "action" ? "space-between" : ""};
	align-items: ${(props) => (props.type === "action" ? "center" : "")};
	span.action {
		padding: 6px 35px;
		border-radius: 5px;
		border: 1px solid #fe673a;
		font-family: "gordita_regular";
	}
`;

const ToggleContainer = styled.div`
	display: inline-block;
	cursor: pointer;
`;

const ToggleButton = styled.div`
	width: 40px;
	height: 25px;
	background-color: ${(props) => (props.isActive ? "#FE673A" : "#ccc")};
	border-radius: 15px;
	position: relative;
	transition: background-color 0.3s ease;

	&::after {
		content: "";
		width: 25px;
		height: 25px;
		background-color: white;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: ${(props) => (props.isActive ? "14px" : "0")};
		transition: left 0.3s ease;
	}
`;

const IconContainer = styled.div`
	width: 20px;
`;

const Icon = styled.img`
	width: 100%;
	display: block;
`;
