// src/components/CompletionRate.js

import React from "react";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(ArcElement, Tooltip, Legend);

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	/* background-color: #1e1e1e; */
	color: #ffffff;
	padding: 4px;
	/* border-radius: 8px;
	width: 200px; */
`;

const Title = styled.h2`
	font-size: 16px;
	margin-bottom: 10px;
`;
const TitleBottom = styled.div`
	font-size: 16px;
	color: #e7e7e7;
	span {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: #fe673a;
		display: inline-block;
		margin-right: 6px;
	}
`;

const ChartContainer = styled.div`
	width: 150px;
	height: 150px;
	margin-bottom: 10px;
`;

const Percentage = styled.div`
	font-size: 24px;
	font-weight: bold;
	margin-top: -40px;
`;

const CompletionRate = ({
	completionRate,
	total_students,
	CompletionRatePercentage,
}) => {
	const data = {
		datasets: [
			{
				data: [completionRate, total_students - completionRate],
				backgroundColor: ["#FE673A", "#d6d6d6"],
				borderWidth: 0,
			},
		],
	};

	const options = {
		cutout: "80%",
		plugins: {
			tooltip: { enabled: false },
			legend: { display: false },
		},
	};

	return (
		<Container>
			<ChartContainer>
				<Doughnut data={data} options={options} />
				<Percentage>{CompletionRatePercentage}</Percentage>
			</ChartContainer>
			<TitleBottom>
				<span></span>Students
			</TitleBottom>
		</Container>
	);
};

export default CompletionRate;
