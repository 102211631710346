import logo from './logo.svg';
import './assets/css/style.css';
import store from './components/contexts/Store'
import Store from './components/contexts/Store';
import MainRouter from './components/routing/routers/Mainrouter';
import { BrowserRouter as Router } from "react-router-dom";



function App() {
  return (
    <Router>
      <Store>
        <MainRouter/>
      </Store>
    </Router>
  );
}

export default App;
