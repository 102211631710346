import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { Context } from "../../contexts/Store";

function AddNewTopicModal({
	mode,
	setAddModal,
	addModal,
	addTopic,
	thumbnail,
	setThumbnail,
	setPublish,
	video,
	orderNumber,
	setOrderNumber,
	setVideo,
	title,
	setTitle,
	description,
	setDescription,
	publish,
}) {
	// const {
	// 	state: {
	// 		user_data: { access_token },
	// 	},
	// } = useContext(Context);

	const fileInputRef = useRef(null);

	console.log(thumbnail, "thumbnail");

	const handleTopicChange = (e) => {
		setVideo(e.target.value);
	};

	const handleLessonNameChange = (e) => {
		setTitle(e.target.value);
	};

	const handleDescriptionChange = (e) => {
		setDescription(e.target.value);
	};

	const handleOrderNumberChange = (e) => {
		setOrderNumber(e.target.value);
	};

	const handleToggleClick = (e) => {
		e.preventDefault();
		setPublish(!publish);
	};

	const onFileChange = (e, type) => {
		if (type === "thumbnail") {
			setThumbnail(e.target.files[0]);
		}
	};

	const thumbnailClick = () => {
		fileInputRef.current.click();
	};

	const handleSave = () => {
		addTopic({
			name: title,
			thumbnail: thumbnail,
			order_id: orderNumber,
			duration: video,
			is_publish: publish,
		});

		setAddModal(false);
	};
	console.log(publish, "publish");

	return (
		<BackContainer style={{ transform: addModal && "scale(1,1)" }}>
			<Overlay
				onClick={() => setAddModal(false)}
				style={{ transform: addModal && "scale(1,1)" }}
			></Overlay>
			<Modal data-effect="mfp-zoom-in">
				<Conatiner>
					<HeadingContainer>
						<Heading>
							{mode === "edit" ? "Edit Lesson" : "Add New Lesson"}
						</Heading>
						<Close>
							<ButtonClose
								onClick={() => {
									setAddModal(false);
									setTitle("");
									setThumbnail("");
									setOrderNumber("");
									setVideo("");
									setPublish("");
									setDescription("");
								}}
							>
								<img
									src={
										require("../../../assets/images/close.svg")
											.default
									}
								/>
							</ButtonClose>
						</Close>
					</HeadingContainer>
					<InputsContainer>
						<AddItem>
							<Label>Thumbnail image *</Label>
							<AttachmentDiv onClick={thumbnailClick}>
								<Icon className="upload">
									<img
										src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-01-2024/upload-cloud.svg"
										alt="upload-icon"
									/>
								</Icon>
								<AttachmentText>
									{thumbnail ? (
										<span>{thumbnail.name}</span>
									) : (
										<>
											<span>Click to upload</span> or drag
											and drop PNG, JPG or MP4 (max. 5MB)
										</>
									)}
								</AttachmentText>
								<FileInput
									type="file"
									accept="image/*"
									ref={fileInputRef}
									onChange={(e) =>
										onFileChange(e, "thumbnail")
									}
								/>
							</AttachmentDiv>
						</AddItem>
						<InputWrapper>
							<Label>Introductory video</Label>
							<Input
								type="text"
								value={video}
								onChange={handleTopicChange}
								placeholder="Add video url"
							/>
						</InputWrapper>
						<InputWrapper>
							<Label>Title</Label>
							<Input
								type="text"
								value={title}
								onChange={handleLessonNameChange}
								placeholder="Enter topic title"
							/>
						</InputWrapper>
						<InputWrapper>
							<Label>Description</Label>
							<TextArea
								value={description}
								onChange={handleDescriptionChange}
								placeholder="Type here..."
							/>
						</InputWrapper>
						<InputWrapper>
							<Label>Order Number</Label>
							<BottomToggle>
								<InputOrder
									value={orderNumber}
									onChange={handleOrderNumberChange}
									placeholder="Type here..."
								/>
								<ToggleWrapper>
									<ToggleContainer
										onClick={handleToggleClick}
									>
										<ToggleButton publish={publish} />
									</ToggleContainer>
									<Label>Publish Now</Label>
								</ToggleWrapper>
							</BottomToggle>
						</InputWrapper>
					</InputsContainer>
					<BottomContainer>
						<Button
							onClick={() => {
								handleSave();
								setAddModal(false);
							}}
						>
							{mode === "edit" ? "Save" : "Add"}
						</Button>
					</BottomContainer>
				</Conatiner>
			</Modal>
		</BackContainer>
	);
}

export default AddNewTopicModal;

// Styled components
const BottomToggle = styled.div`
	display: flex;
	align-items: center;
`;
const BackContainer = styled.div`
	position: fixed;
	transition: 0.5s;
	transform: scale(0, 0);
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
	transform: scale(0);
`;
const ToggleContainer = styled.div`
	display: inline-block;
	cursor: pointer;
	margin-right: 10px;
`;
const FileInput = styled.input`
	display: none;
`;
const InputOrder = styled.input`
	width: 44%;
	height: 50px;
	border: 1px solid #3b3b3b;
	border-radius: 5px;
	padding: 0 10px;
	margin-right: 20px;
	background: #161619;
	color: #fff;
`;
const ToggleButton = styled.div`
	width: 30px;
	height: 17px;
	background-color: ${(props) => (props.publish ? "#FE673A" : "#ccc")};
	border-radius: 15px;
	position: relative;
	transition: background-color 0.3s ease;

	&::after {
		content: "";
		width: 16px;
		height: 16px;
		background-color: white;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: ${(props) => (props.publish ? "14px" : "0")};
		transition: left 0.3s ease;
	}
`;
const Modal = styled.div`
	width: 550px;
	max-width: 550px;
	height: ${({ textarea }) => (textarea ? "90vh" : "")};
	margin: 0 auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	transition: 0.5s;
	z-index: 101;
	border: 1px solid #3b3b3b;
	background: #1b1c1f;
	border-radius: 5px;
	box-shadow: 0px 3px 56px #00000048;

	@media all and (max-width: 980px) {
		width: 550px;
	}
	@media all and (max-width: 768px) {
		width: 400px;
	}
	@media all and (max-width: 640px) {
		width: 400px;
	}
	@media all and (max-width: 480px) {
		width: 340px;
	}
	@media all and (max-width: 360px) {
		width: 300px;
	}
`;
const Conatiner = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
	height: 100%;
	padding-bottom: 50px;

	@media all and (max-width: 640px) {
		padding: 28px;
	}
`;
const HeadingContainer = styled.div`
	border-bottom: 1px solid #3b3b3b;
	padding-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Heading = styled.h3`
	font-family: "gordita_medium";
	font-size: 22px;
	color: #fff;
`;
const Close = styled.div`
	cursor: pointer;
`;
const ButtonClose = styled.div`
	width: 20px;
	img {
		display: block;
		width: 100%;
	}
`;
const InputsContainer = styled.div`
	margin-top: 20px;
`;
const InputWrapper = styled.div`
	margin-bottom: 20px;
	text-align: start;
`;
const Label = styled.label`
	color: #c3c3c3;
	font-family: "gordita_medium";
	font-size: 14px;
	margin-bottom: 5px;
	display: block;
`;
const TextArea = styled.textarea`
	width: 100%;
	height: 100px;
	padding: 10px;
	border: 1px solid #3b3b3b;
	border-radius: 5px;
	background: #161619;
	color: #fff;
`;
const Input = styled.input`
	width: 100%;
	height: 50px;
	border: 1px solid #3b3b3b;
	border-radius: 5px;
	padding: 0 10px;
	background: #161619;
	color: #fff;
`;
const ToggleWrapper = styled.div`
	display: flex;
	align-items: normal;
`;

const BottomContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;
const Button = styled.div`
	width: 16%;
	height: 38px;
	border: 1px solid #fe673a;
	border-radius: 5px;
	display: inline-block;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: #fe673a;
	font-family: "gordita_regular";
	color: #fff;

	@media all and (max-width: 480px) {
		/* width: 40%;
    height: 40px; */
	}
`;
const AddItem = styled.div`
	position: relative;
	margin-bottom: 20px;
	:last-child {
		margin-bottom: 0;
	}
	&.addValue {
		display: flex;
		justify-content: space-between;
	}
`;

const AttachmentDiv = styled.div`
	border-radius: 12px;
	border: 1px solid #3d3d3d;
	background: #161619;
	display: flex;
	padding: 16px 24px;
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
	cursor: pointer;
	.drag-active {
		&:hover {
			filter: blur(5px);
			transition: filter 0.3s ease;
		}
	}
`;
const Icon = styled.span`
	display: inline-block;
	width: 20px;
	&.upload {
		width: 40px;
		height: 40px;
		padding: 10px;
		border-radius: 8px;
		border: 1px solid #3d3d3d;
		background: #161619;
	}
	&.file-icon {
		width: 40px;
	}
	img {
		width: 100%;
		display: block;
	}
`;
const AttachmentText = styled.div`
	width: 50%;
	margin-top: 12px;
	font-size: 12px;
	color: #94a3b8;
	font-family: "gordita_regular";
	line-height: 18px;
	text-align: center;
	/* max-height: 150px; */

	img {
		/* width: 100%; */
		height: 80%;
		display: block;
	}

	span {
		font-family: "gordita_medium";
		color: #fe673a;
		font-size: 14px;
		display: inline-block;
		margin-right: 4px;
	}
`;
