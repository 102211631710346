import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { Context } from "../../contexts/Store";

import SectionLoader from "../../helpers/SectionLoader";

import ButtonLoader from "../../genaral/Loader/ButtonLoader";

import upArrow from "../../../assets/icons/up-arrow.svg";
import downArrow from "../../../assets/icons/down-arrow.svg";
export default function UsersSinglePage() {
	const [deviceDetails, setDeviceDetails] = useState([]);
	const [deviceLoading, setDeviceLoading] = useState(true);
	const {
		state: {
			user_data: { access_token },
		},
		dispatch,
	} = useContext(Context);
	const { student_id } = useParams();
	const [studentDetails, setStudentDetails] = useState({});
	const [studentLoading, setStudentLoading] = useState(false);
	const [isCampusActive, setCampusActive] = useState(false);
	const [isEnabled, setEnabled] = useState(false);
	const [isBottomDivVisible, setIsBottomDivVisible] = useState(false);
	const [isEdit, setEdit] = useState(false);
	const [selectedDistrict, setSelectedDistrict] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [dayloading, setDayloading] = useState(false);
	const [isModal, setModal] = useState(false);

	useEffect(() => {
		dispatch({
			type: "UPDATE_STUDENT_DETAILS",
			student_data: {
				user_id: studentDetails.user_id,
				student_name: studentDetails.name,
				category: studentDetails.program,
			},
		});
	}, [studentDetails]);

	// useEffect(() => {
	// 	fetchStudents();
	// 	devicedetails();
	// }, [isLoading]);

	// const fetchStudents = () => {
	// 	setStudentLoading(true);
	// 	// accountsConfig
	// 	get(`/api/v1/users/sro/student-profile-section/${student_id}/`, {
	// 		headers: {
	// 			Authorization: `Bearer ${access_token}`,
	// 		},
	// 	})
	// 		.then((response) => {
	// 			let { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setStudentDetails(data);
	// 				setStudentLoading(false);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			setStudentLoading(true);
	// 		});
	// };

	//----------------------active devices list
	//////////////////////////////////////////////

	// const devicedetails = () => {
	// 	setDeviceLoading(true);
	// 	get(`/api/v1/users/sro/get-student-active-devices/${student_id}/`, {
	// 		headers: {
	// 			Authorization: `Bearer ${access_token}`,
	// 		},
	// 	})
	// 		.then((response) => {
	// 			let { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setDeviceDetails(data);
	// 				setDeviceLoading(false);
	// 			} else {
	// 				setDeviceLoading(false);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			setDeviceLoading(false);
	// 		});
	// };

	// useEffect(() => {
	// 	if (selectedDistrict) {
	// 		setEnabled(true);
	// 		setLoading(false);
	// 	}
	// }, [selectedDistrict]);

	// ====== district add and edit api =======

	// const districtConfirmation = () => {
	// 	setLoading(true);
	// 	const formData = new FormData();
	// 	formData.append("district", selectedDistrict);
	// 	post(
	// 		`api/v1/users/sro/student-profile-add-district/${student_id}/`,
	// 		formData,
	// 		{
	// 			headers: {
	// 				Authorization: `Bearer ${access_token}`,
	// 			},
	// 		}
	// 	)
	// 		.then((response) => {
	// 			let { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setLoading(false);
	// 				setEdit(false);
	// 				setEnabled(false);
	// 			} else {
	// 				setLoading(false);
	// 			}
	// 			setLoading(false);
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			setDeviceLoading(false);
	// 		});
	// };
	// ==== create days ===== //
	// const createDays = () => {
	// 	setDayloading(true);
	// 	const formData = new FormData();
	// 	formData.append("student_id", student_id);
	// 	formData.append("program_slug", studentDetails.program);
	// 	post(`/learn/create-student-days/`, formData, {
	// 		headers: {
	// 			Authorization: `Bearer ${access_token}`,
	// 		},
	// 	})
	// 		.then((response) => {
	// 			let { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setDayloading(false);
	// 			} else {
	// 				setDayloading(false);
	// 			}
	// 			setDayloading(false);
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			setDayloading(false);
	// 		});
	// };

	const toggleVisibility = () => {
		setIsBottomDivVisible(!isBottomDivVisible);
	};

	// ==== district list =====

	return (
		<>
			<div id="main">
				<Heading>Profile Details</Heading>
				<SubHead>Dashboard / Digital and Finance / Haifa IT</SubHead>

				{studentLoading ? (
					<SectionLoader />
				) : (
					<Container>
						<TopCover>
							<Cover>
								<TopSection>
									<LeftName>
										<ProfilePhoto>
											<Profile
												src={
													require("../../../assets/icons/profile-avatar.svg")
														.default
												}
												alt="image"
											/>
										</ProfilePhoto>
										<ProfileId>
											<StudentName>Haifa I T</StudentName>
											<StudentID>#034</StudentID>
										</ProfileId>
										<OwnerButton>Owner</OwnerButton>
									</LeftName>
									<ButtonContainer>
										{!dayloading ? (
											<Createdays
												onClick={() => {
													// createDays();
												}}
											>
												Reset Password
											</Createdays>
										) : (
											<Createdays className="create">
												<ButtonLoader />
											</Createdays>
										)}
										{/* <ButtonEdit
										onClick={() => setModal(!isModal)}
									>
										Edit
									</ButtonEdit> */}
									</ButtonContainer>
								</TopSection>
								<BottomHead>Info</BottomHead>
								<TopBottomSection>
									<Category>
										<Card>
											<Namelabel>Mobile Number</Namelabel>
											{studentDetails.phone ? (
												<Name>
													{studentDetails.phone}
												</Name>
											) : (
												<Name>--</Name>
											)}
										</Card>
										<Card>
											<Namelabel>Date joined</Namelabel>
											{studentDetails.dob ? (
												<Name>
													{studentDetails.dob}
												</Name>
											) : (
												<Name>--</Name>
											)}
										</Card>
										{/* <Card>
											<Namelabel>Full name</Namelabel>
											<Name>{studentDetails.name}</Name>
										</Card>
										<Card>
											<Namelabel>Gender</Namelabel>
											{studentDetails.gender ? (
												<Name>
													{studentDetails.gender}
												</Name>
											) : (
												<Name>--</Name>
											)}
										</Card> */}
									</Category>
									<Category>
										{/* <Card>
											<Namelabel>Category</Namelabel>
											{studentDetails.program ? (
												<Name>
													{studentDetails.program.replace(
														"-",
														" "
													)}
												</Name>
											) : (
												<Name>--</Name>
											)}
										</Card>
										<Card>
											<Namelabel>Campus</Namelabel>
											{studentDetails.campus ? (
												<Name>
													{studentDetails.campus}
												</Name>
											) : (
												<Name>--</Name>
											)}
										</Card> */}
										<Card>
											<Namelabel>District</Namelabel>
											<Name>Thiruvananthapuram</Name>
										</Card>
										<Card>
											<Namelabel>Local Body</Namelabel>
											<Name>Pangode</Name>
										</Card>
										<Card>
											<Namelabel>Ward</Namelabel>
											<Name>01 - Pulippara</Name>
										</Card>
									</Category>
									<Category>
										<Card>
											<Namelabel>Campus Name</Namelabel>{" "}
											<Name>Govt. HSS Karuvarakundu</Name>
										</Card>
										<Card>
											<Namelabel>Campus Code</Namelabel>{" "}
											<Name>GSKRMP</Name>
										</Card>
										<Card>
											<Namelabel>Class</Namelabel>{" "}
											<Name>10</Name>
										</Card>
										<Card>
											<Namelabel>Divsion</Namelabel>{" "}
											<Name>B</Name>
										</Card>
									</Category>
									<Category>
										<Card>
											<Namelabel>Department</Namelabel>{" "}
											<Name>English</Name>
										</Card>
										<Card>
											<Namelabel>Institution</Namelabel>{" "}
											<Name>JNEO02</Name>
										</Card>
									</Category>
								</TopBottomSection>
								<BottomSectionContainer>
									<BottomHeading>
										Programs Enrolled
									</BottomHeading>
									<BoxContainer>
										<Box>
											<BoxTop>
												<BoxTopLeft>
													<BoxHeadIconContainer>
														<BoxHeadIcon
															src={
																require("../../../assets/icons/Icon.svg")
																	.default
															}
															alt="dot icon"
														/>
													</BoxHeadIconContainer>

													<BoxHead>
														Digital and Finance
													</BoxHead>
												</BoxTopLeft>
												<BoxTopRight>
													{/* <ProgressButton>
														<DotContainer>
															<Dot />
														</DotContainer>
														<ProgressButtonContent>
															In Progress
														</ProgressButtonContent>
													</ProgressButton> */}
													<ProgressContainer>
														<DotContainer>
															<DotImage
																src={
																	require("../../../assets/icons/dot.svg")
																		.default
																}
																alt="dot icon"
															/>
														</DotContainer>
														<Progress>
															In Progress
														</Progress>
													</ProgressContainer>
												</BoxTopRight>
											</BoxTop>
											<Card>
												<Namelabel>
													Enrolled Date
												</Namelabel>{" "}
												<Name>Jan 10, 2024</Name>
											</Card>
											<Card>
												<Namelabel>
													Current Topic
												</Namelabel>{" "}
												<Name>
													EIntroduction to Digital
													Finance
												</Name>
												<SubName>
													Importance of Finance
												</SubName>
											</Card>
										</Box>
										<Box>
											<BoxTop>
												<BoxTopLeft>
													<BoxHeadIconContainer>
														<BoxHeadIcon
															src={
																require("../../../assets/icons/Icon.svg")
																	.default
															}
															alt="dot icon"
														/>
													</BoxHeadIconContainer>

													<BoxHead>
														Digital and Finance
													</BoxHead>
												</BoxTopLeft>
												<BoxTopRight>
													{/* <ProgressButton>
														<DotContainer>
															<Dot />
														</DotContainer>
														<ProgressButtonContent>
															In Progress
														</ProgressButtonContent>
													</ProgressButton> */}
													<ProgressContainer>
														<DotContainer>
															<DotImage
																src={
																	require("../../../assets/icons/dot.svg")
																		.default
																}
																alt="dot icon"
															/>
														</DotContainer>
														<Progress>
															In Progress
														</Progress>
													</ProgressContainer>
												</BoxTopRight>
											</BoxTop>
											<BoxBottomCompleted>
												<Card>
													<Namelabel>
														Enrolled Date
													</Namelabel>{" "}
													<Name>Jan 10, 2024</Name>
												</Card>
												<Card>
													<Namelabel>
														Certificate Status
													</Namelabel>{" "}
													<Name>Not Downloaded</Name>
												</Card>
											</BoxBottomCompleted>
											<DownloadButton>
												<Download>
													Download Certificate
												</Download>
												<DownloadIconContainer>
													<DownloadIcon
														src={
															require("../../../assets/icons/download.svg")
																.default
														}
														alt="download icon"
													/>
												</DownloadIconContainer>
											</DownloadButton>
										</Box>
										<Box>
											<BoxTop>
												<BoxTopLeft>
													<BoxHeadIconContainer>
														<BoxHeadIcon
															src={
																require("../../../assets/icons/Icon.svg")
																	.default
															}
															alt="dot icon"
														/>
													</BoxHeadIconContainer>

													<BoxHead>
														Digital and Finance
													</BoxHead>
												</BoxTopLeft>
												<BoxTopRight>
													{/* <ProgressButton>
														<DotContainer>
															<Dot />
														</DotContainer>
														<ProgressButtonContent>
															In Progress
														</ProgressButtonContent>
													</ProgressButton> */}
													<ProgressContainer>
														<DotContainer>
															<DotImage
																src={
																	require("../../../assets/icons/dot.svg")
																		.default
																}
																alt="dot icon"
															/>
														</DotContainer>
														<Progress>
															In Progress
														</Progress>
													</ProgressContainer>
												</BoxTopRight>
											</BoxTop>
											<Card>
												<Namelabel>
													Enrolled Date
												</Namelabel>{" "}
												<Name>Jan 10, 2024</Name>
											</Card>
											<Card>
												<Namelabel>
													Current Topic
												</Namelabel>{" "}
												<Name>
													EIntroduction to Digital
													Finance
												</Name>
												<SubName>
													Importance of Finance
												</SubName>
											</Card>
										</Box>
									</BoxContainer>
								</BottomSectionContainer>
							</Cover>
						</TopCover>
						<Profiles>
							<TopCover>
								<Cover>
									<TopSection>
										<LeftName>
											<ProfilePhoto>
												<Profile
													src={
														require("../../../assets/icons/profile-avatar.svg")
															.default
													}
													alt="image"
												/>
											</ProfilePhoto>
											<ProfileId>
												<StudentName>
													Haifa I T
												</StudentName>
												<StudentID>#034</StudentID>
											</ProfileId>
											{/* <OwnerButton>Owner</OwnerButton> */}
										</LeftName>
										<ArrowIconContainer
											onClick={toggleVisibility}
										>
											<ArrowIcon
												src={
													isBottomDivVisible
														? upArrow
														: downArrow
												}
												alt="toggle icon"
											/>
										</ArrowIconContainer>
									</TopSection>
									{isBottomDivVisible && (
										<BottomDiv
											isVisible={isBottomDivVisible}
										>
											<BottomHead>Info</BottomHead>
											<TopBottomSection>
												<Category>
													<Card>
														<Namelabel>
															Mobile Number
														</Namelabel>
														{studentDetails.phone ? (
															<Name>
																{
																	studentDetails.phone
																}
															</Name>
														) : (
															<Name>--</Name>
														)}
													</Card>
													<Card>
														<Namelabel>
															Date joined
														</Namelabel>
														{studentDetails.dob ? (
															<Name>
																{
																	studentDetails.dob
																}
															</Name>
														) : (
															<Name>--</Name>
														)}
													</Card>
													{/* <Card>
											<Namelabel>Full name</Namelabel>
											<Name>{studentDetails.name}</Name>
										</Card>
										<Card>
											<Namelabel>Gender</Namelabel>
											{studentDetails.gender ? (
												<Name>
													{studentDetails.gender}
												</Name>
											) : (
												<Name>--</Name>
											)}
										</Card> */}
												</Category>
												<Category>
													{/* <Card>
											<Namelabel>Category</Namelabel>
											{studentDetails.program ? (
												<Name>
													{studentDetails.program.replace(
														"-",
														" "
													)}
												</Name>
											) : (
												<Name>--</Name>
											)}
										</Card>
										<Card>
											<Namelabel>Campus</Namelabel>
											{studentDetails.campus ? (
												<Name>
													{studentDetails.campus}
												</Name>
											) : (
												<Name>--</Name>
											)}
										</Card> */}
													<Card>
														<Namelabel>
															District
														</Namelabel>
														<Name>
															Thiruvananthapuram
														</Name>
													</Card>
													<Card>
														<Namelabel>
															Local Body
														</Namelabel>
														<Name>Pangode</Name>
													</Card>
													<Card>
														<Namelabel>
															Ward
														</Namelabel>
														<Name>
															01 - Pulippara
														</Name>
													</Card>
												</Category>
												<Category>
													<Card>
														<Namelabel>
															Campus Name
														</Namelabel>{" "}
														<Name>
															Govt. HSS
															Karuvarakundu
														</Name>
													</Card>
													<Card>
														<Namelabel>
															Campus Code
														</Namelabel>{" "}
														<Name>GSKRMP</Name>
													</Card>
													<Card>
														<Namelabel>
															Class
														</Namelabel>{" "}
														<Name>10</Name>
													</Card>
													<Card>
														<Namelabel>
															Divsion
														</Namelabel>{" "}
														<Name>B</Name>
													</Card>
												</Category>
												<Category>
													<Card>
														<Namelabel>
															Department
														</Namelabel>{" "}
														<Name>English</Name>
													</Card>
													<Card>
														<Namelabel>
															Institution
														</Namelabel>{" "}
														<Name>JNEO02</Name>
													</Card>
												</Category>
											</TopBottomSection>
											<BottomSectionContainer>
												<BottomHeading>
													Programs Enrolled
												</BottomHeading>
												<BoxContainer>
													<Box>
														<BoxTop>
															<BoxTopLeft>
																<BoxHeadIconContainer>
																	<BoxHeadIcon
																		src={
																			require("../../../assets/icons/Icon.svg")
																				.default
																		}
																		alt="dot icon"
																	/>
																</BoxHeadIconContainer>

																<BoxHead>
																	Digital and
																	Finance
																</BoxHead>
															</BoxTopLeft>
															<BoxTopRight>
																{/* <ProgressButton>
														<DotContainer>
															<Dot />
														</DotContainer>
														<ProgressButtonContent>
															In Progress
														</ProgressButtonContent>
													</ProgressButton> */}
																<ProgressContainer>
																	<DotContainer>
																		<DotImage
																			src={
																				require("../../../assets/icons/dot.svg")
																					.default
																			}
																			alt="dot icon"
																		/>
																	</DotContainer>
																	<Progress>
																		In
																		Progress
																	</Progress>
																</ProgressContainer>
															</BoxTopRight>
														</BoxTop>
														<Card>
															<Namelabel>
																Enrolled Date
															</Namelabel>{" "}
															<Name>
																Jan 10, 2024
															</Name>
														</Card>
														<Card>
															<Namelabel>
																Current Topic
															</Namelabel>{" "}
															<Name>
																EIntroduction to
																Digital Finance
															</Name>
															<SubName>
																Importance of
																Finance
															</SubName>
														</Card>
													</Box>
													<Box>
														<BoxTop>
															<BoxTopLeft>
																<BoxHeadIconContainer>
																	<BoxHeadIcon
																		src={
																			require("../../../assets/icons/Icon.svg")
																				.default
																		}
																		alt="dot icon"
																	/>
																</BoxHeadIconContainer>

																<BoxHead>
																	Digital and
																	Finance
																</BoxHead>
															</BoxTopLeft>
															<BoxTopRight>
																{/* <ProgressButton>
														<DotContainer>
															<Dot />
														</DotContainer>
														<ProgressButtonContent>
															In Progress
														</ProgressButtonContent>
													</ProgressButton> */}
																<ProgressContainer>
																	<DotContainer>
																		<DotImage
																			src={
																				require("../../../assets/icons/dot.svg")
																					.default
																			}
																			alt="dot icon"
																		/>
																	</DotContainer>
																	<Progress>
																		In
																		Progress
																	</Progress>
																</ProgressContainer>
															</BoxTopRight>
														</BoxTop>
														<BoxBottomCompleted>
															<Card>
																<Namelabel>
																	Enrolled
																	Date
																</Namelabel>{" "}
																<Name>
																	Jan 10, 2024
																</Name>
															</Card>
															<Card>
																<Namelabel>
																	Certificate
																	Status
																</Namelabel>{" "}
																<Name>
																	Not
																	Downloaded
																</Name>
															</Card>
														</BoxBottomCompleted>
														<DownloadButton>
															<Download>
																Download
																Certificate
															</Download>
															<DownloadIconContainer>
																<DownloadIcon
																	src={
																		require("../../../assets/icons/download.svg")
																			.default
																	}
																	alt="download icon"
																/>
															</DownloadIconContainer>
														</DownloadButton>
													</Box>
													<Box>
														<BoxTop>
															<BoxTopLeft>
																<BoxHeadIconContainer>
																	<BoxHeadIcon
																		src={
																			require("../../../assets/icons/Icon.svg")
																				.default
																		}
																		alt="dot icon"
																	/>
																</BoxHeadIconContainer>

																<BoxHead>
																	Digital and
																	Finance
																</BoxHead>
															</BoxTopLeft>
															<BoxTopRight>
																{/* <ProgressButton>
														<DotContainer>
															<Dot />
														</DotContainer>
														<ProgressButtonContent>
															In Progress
														</ProgressButtonContent>
													</ProgressButton> */}
																<ProgressContainer>
																	<DotContainer>
																		<DotImage
																			src={
																				require("../../../assets/icons/dot.svg")
																					.default
																			}
																			alt="dot icon"
																		/>
																	</DotContainer>
																	<Progress>
																		In
																		Progress
																	</Progress>
																</ProgressContainer>
															</BoxTopRight>
														</BoxTop>
														<Card>
															<Namelabel>
																Enrolled Date
															</Namelabel>{" "}
															<Name>
																Jan 10, 2024
															</Name>
														</Card>
														<Card>
															<Namelabel>
																Current Topic
															</Namelabel>{" "}
															<Name>
																EIntroduction to
																Digital Finance
															</Name>
															<SubName>
																Importance of
																Finance
															</SubName>
														</Card>
													</Box>
												</BoxContainer>
											</BottomSectionContainer>
										</BottomDiv>
									)}
								</Cover>
							</TopCover>
						</Profiles>
					</Container>
				)}
			</div>
		</>
	);
}
const BottomDiv = styled.div`
	/* display: ${(props) => (props.isBottomDivVisible ? "block" : "none")}; */
	display: ${(props) => (props.isVisible ? "block" : "none")};
`;

const Profiles = styled.div`
	margin-bottom: 100px;
`;
const Container = styled.div``;
// const Heading = styled.h4`
// 	font-size: 20px;
// 	font-family: "gordita_medium";
// 	color: #fff;
// 	margin-bottom: 15px !important;
// `;
const TopCover = styled.div`
	padding: 25px;
	margin-bottom: 40px;
	border: 1px solid #26272a;
	border-radius: 5px;
	background: #1b1c1f;
	@media all and (max-width: 1350px) {
	}
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const Cover = styled.div`
	min-width: 1100px;
`;
const ProgressContainer = styled.div`
	display: flex;
	align-items: center;
	background: #f9fafb;
	border: 1px solid #eaecf0;
	padding: 1px 5px;
	border-radius: 8px;
`;
const DotContainer = styled.div`
	width: 8px;
	margin-right: 4px;
`;
const DotImage = styled.img`
	display: block;
	width: 100%;
`;
const Progress = styled.h6`
	color: #344054;
	font-family: "gordita_regular";
	font-size: 12px;
`;
const TopSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 30px;
	margin-bottom: 20px;
`;
const LeftName = styled.div`
	display: flex;
	align-items: center;
	width: 30%;
`;
const ProfileId = styled.div`
	margin-right: 20px;
`;
const StudentName = styled.h6`
	font-size: 16px;
	color: #fff;
	font-family: gordita_medium;
`;
const ButtonContainer = styled.div`
	display: flex;
`;
const StudentID = styled.p`
	color: #f56939;
`;

const OwnerButton = styled.div`
	border: 1px solid #b2ddff;
	background: #eff8ff;
	color: #175cd3;
	padding: 4px 8px 4px 8px;
	border-radius: 8px;
	height: 26px;
	font-size: 12px;
`;
const BoxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const BoxBottomCompleted = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const DownloadButton = styled.div`
	border: 1px solid #fe673a;
	border-radius: 6px;
	padding: 12px 12px 12px 16px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Download = styled.h6`
	color: #fe673a;
	margin-right: 12px;
	font-size: 14px;
	font-weight: "gordita_bold";
`;

const DownloadIconContainer = styled.div`
	width: 18px;
`;

const DownloadIcon = styled.img`
	display: block;
	width: 100%;
`;

const Box = styled.div`
	border: 1px solid #2c2c2c;
	border-radius: 12px;
	padding: 24px;
	width: 32%;
`;
const BoxTop = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #3d3d3d;
	padding-bottom: 16px;
	margin-bottom: 20px;
`;
const BoxTopLeft = styled.div`
	display: flex;
	align-items: center;
`;
const BoxHeadIconContainer = styled.div`
	width: 20px;
	margin-right: 10px;
`;
const BoxHeadIcon = styled.img`
	width: 100%;
	display: block;
`;
const BoxHead = styled.h5`
	color: #ffffff;
	font-size: 18px;
	font-weight: "gordita_bold";
`;
const BoxTopRight = styled.div``;

const Button = styled.div`
	padding: 8px 25px;
	background-color: ${(props) => (props.type !== true ? "#3fbe6c" : "red")};
	border-radius: 5px;
	color: #fff;
	font-family: gordita_medium;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	&.create {
		padding: 4px 25px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
const Heading = styled.h3`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 24px;
	margin-bottom: 10px;
`;

const SubHead = styled.h5`
	color: #b7b7b7;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 26px;
`;
const EditCover = styled.div`
	display: flex;
	align-items: center;
	&.none {
		display: none;
	}
`;
const EditButton = styled.div`
	color: #fff;
	width: 8%;
	display: flex;
	justify-content: center;
	font-size: 21px;
	border: 1px solid #fff;
	padding: 4px;
	border-radius: 6px;
	padding: 4px;
	border-radius: 8px;
	margin-right: 10px;
	cursor: pointer;
	img {
		width: 100%;
		display: block;
	}
`;
const TopBottomSection = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-bottom: 20px;
	border-bottom: 1px solid #3d3d3d;
`;
const BottomSectionContainer = styled.div``;
const BottomHeading = styled.div`
	font-size: 16px;
	color: #fff;
	font-family: "gordita_medium";
	margin-bottom: 20px;
`;

const BottomHead = styled.div`
	font-size: 16px;
	color: #fff;
	font-family: "gordita_medium";
`;
const Category = styled.div`
	width: 25%;
`;
const Namelabel = styled.h6`
	font-family: gordita_medium;
	margin-bottom: 5px;
	font-size: 14px;
	color: #e8e8e899;
`;
const Name = styled.h5`
	color: #fff;
	font-size: 14px;

	text-transform: capitalize;
	&.lowercase {
		text-transform: lowercase;
	}
	/* height: 24px; */
`;
const SubName = styled.h5`
	color: #fff;
	font-size: 16px;
	font-family: "gordita_regular";

	/* height: 24px; */
`;
const HeaderName = styled.p`
	color: #fff;
	font-size: 14px;
	width: 20%;
	font-family: gordita_medium;
	:last-child {
		text-align: right;
	}
`;
const TechGreen = styled.p`
	color: ${(props) =>
		props.type === false
			? "#3fbe6c"
			: props.type === true
			? "red"
			: "#fff"};
	color: ${(props) => (props.type === "middle" ? "#fff" : "")};
	margin-bottom: 10px;
	text-align: right;
	font-size: 14px;
	:last-child {
		margin-bottom: 0;
	}
`;

const TextActive = styled.p`
	color: #fff;
	font-family: gordita_medium;
	font-size: 16px;
	margin: 20px 0 15px;
`;
const BottomSection = styled.div`
	padding: 25px;
	background: #1b1c1f;
	border-radius: 5px;
	border: 1px solid #26272a;
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const ArrowIconContainer = styled.div``;
const ArrowIcon = styled.img``;
const ButtonEdit = styled.div`
	padding: 8px 25px;
	border: 1px solid #fa643f;
	cursor: pointer;
	border-radius: 5px;
	color: #fff;
	font-family: gordita_medium;
	font-size: 14px;
	margin-right: 10px;
`;
const Createdays = styled.div`
	font-family: gordita_medium;
	cursor: pointer;
	margin-right: 10px;
	background: #da5b36;
	color: #fff;
	border-radius: 5px;
	width: 135px;
	height: 40px;
	padding: 0 10px;
	justify-content: center;
	display: flex;
	font-size: 14px;
	align-items: center;

	&.create {
		width: 120px;
		height: 40px;
	}
`;

const BottomBottomSection = styled.div`
	padding-top: 30px;
`;
const BottomCover = styled.div`
	min-width: 1100px;
`;
const BottomTopSection = styled.div`
	border-bottom: 1px solid #161618;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 30px;
`;
const AddButton = styled.div`
	cursor: pointer;
	width: 8%;
	margin: 0 auto 0px 24px;
	border: 1px solid #fff;
	padding: 4px;
	border-radius: 6px;
	&.none {
		display: none;
	}
	img {
		width: 100%;
		display: block;
	}
`;
const Card = styled.div`
	margin-bottom: 20px;
	margin-right: 10px;
	position: relative;
	:last-child {
		margin-bottom: 0;
	}
`;
const MiniCover = styled.select`
	border: 1px solid #26272a;
	background: #1b1c1f;

	padding: 10px;
	position: absolute;
	top: 28px;
	color: #fff;
`;
const List = styled.option`
	border-bottom: 1px solid #26272a;
	background: #1b1c1f !important;
`;

const ActiveDevicesCard = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
	:last-child {
		margin-bottom: 0;
	}
`;

const DeviceCover = styled.div`
	display: flex;
	align-items: center;

	width: 20%;
	:last-child {
		justify-content: end;
	}
`;
const CompanyRight = styled.div``;
const CompanyName = styled.p`
	color: #fff;
	font-size: 14px;
`;

const CompanyLocation = styled.p`
	color: #fff;
	text-align: right;
	width: 100%;
`;

const ProfilePhoto = styled.div`
	width: 80px;
	margin-right: 20px;
`;
const Profile = styled.img`
	display: block;
	width: 100%;
`;
