// import { useState, useContext } from "react";
// import styled from "styled-components";
// import { Context } from "../../contexts/Store";
// import ButtonLoader from "../../genaral/Loader/ButtonLoader";

// function EditQuestionModal({
// 	AddModal,
// 	setAddModal,
// 	addNewQuestion,
// 	question,
// 	questiondata,
// 	optionsA,
// 	optionsB,
// 	optionsC,
// 	optionsD,
// 	setOptionsA,
// 	setOptionsB,
// 	setOptionsC,
// 	setOptionsD,
// 	editQestionData,
// 	setEditQuestionData,
// 	correctOption,
// 	setCorrectOption,
// 	setQuestion,
// }) {
// 	const { state } = useContext(Context);
// 	const access_token = state.user_data.access_token;

// 	const handleSave = () => {
// 		addNewQuestion({
// 			// name: title,
// 			// thumbnail: thumbnail,
// 			// order_id: orderNumber,
// 			// duration: video,
// 			// is_publish: publish, // Include the isActive state in the payload
// 		});
// 		setAddModal(false);
// 	};
// 	console.log(questiondata, "dfghjk");

// 	return (
// 		<BackContainer>
// 			<Cover className={AddModal ? "active" : ""}>
// 				<Overlay
// 					onClick={() => {
// 						setAddModal(false);
// 					}}
// 				></Overlay>
// 				<Modal>
// 					<Container>
// 						<Head
// 							onClick={() => {
// 								setAddModal(false);
// 							}}
// 						>
// 							<Headtitle>Add Question</Headtitle>
// 							<CloseIcon>
// 								<img
// 									src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
// 									alt="Icon"
// 								/>
// 							</CloseIcon>
// 						</Head>

// 						<Servicesfields>
// 							<Label>Question</Label>

// 							<SelectCampusInput
// 								className={!question && "error"}
// 								placeholder="Enter your Question"
// 								value={
// 									questiondata.question
// 										? questiondata.question
// 										: question
// 										? question
// 										: ""
// 								}
// 								onChange={(e) => {
// 									setQuestion(e.target.value);
// 								}}
// 							/>
// 						</Servicesfields>
// 						<AddExaminers>
// 							<Label>Option A</Label>
// 							<InputField className={!optionsA && "error"}>
// 								<input
// 									placeholder="Enter option A"
// 									type="text"
// 									value={
// 										questiondata.option1
// 											? questiondata.option1
// 											: optionsA
// 									}
// 									onChange={(e) => {
// 										setOptionsA(e.target.value);
// 									}}
// 								/>
// 							</InputField>
// 						</AddExaminers>
// 						<AddExaminers>
// 							<Label>Option B</Label>
// 							<InputField className={!optionsB && "error"}>
// 								<input
// 									placeholder="Enter option B"
// 									type="text"
// 									value={
// 										questiondata.option2
// 											? questiondata.option2
// 											: optionsB
// 											? optionsB
// 											: ""
// 									}
// 									onChange={(e) => {
// 										setOptionsB(e.target.value);
// 									}}
// 								/>
// 							</InputField>
// 						</AddExaminers>
// 						<AddExaminers>
// 							<Label>Option C</Label>
// 							<InputField className={!optionsC && "error"}>
// 								<input
// 									placeholder="Enter option C"
// 									type="text"
// 									value={
// 										questiondata.option3
// 											? questiondata.option3
// 											: optionsC
// 									}
// 									onChange={(e) => {
// 										setOptionsC(e.target.value);
// 									}}
// 								/>
// 							</InputField>
// 						</AddExaminers>
// 						<AddExaminers>
// 							<Label>Option D</Label>
// 							<InputField className={!optionsD && "error"}>
// 								<input
// 									placeholder="Enter option D"
// 									type="text"
// 									value={
// 										questiondata.option4
// 											? questiondata.option4
// 											: optionsD
// 									}
// 									onChange={(e) => {
// 										setOptionsD(e.target.value);
// 									}}
// 								/>
// 							</InputField>
// 						</AddExaminers>
// 						<AddExaminers>
// 							<Label>Correct Answer</Label>
// 							<InputField className={!correctOption && "error"}>
// 								<select
// 									onChange={(e) =>
// 										setCorrectOption(e.target.value)
// 									}
// 								>
// 									<option placeholder="" disabled selected>
// 										Select Right Answer
// 									</option>
// 									<option value="option1">A</option>
// 									<option value="option2">B</option>
// 									<option value="option3">C</option>
// 									<option value="option4">D</option>
// 								</select>
// 							</InputField>
// 						</AddExaminers>
// 						<CreateButtonBox>
// 							<CreateButton
// 								onClick={() => {
// 									if (
// 										optionsA &&
// 										optionsB &&
// 										optionsC &&
// 										optionsD &&
// 										questiondata.right_option
// 											? questiondata.right_option
// 											: correctOption
// 									)
// 										handleSave();
// 									setAddModal(false);
// 								}}
// 							>
// 								"Submit"
// 							</CreateButton>
// 						</CreateButtonBox>
// 					</Container>
// 				</Modal>
// 			</Cover>
// 		</BackContainer>
// 	);
// }

// export default EditQuestionModal;

// const Cover = styled.div`
// 	position: fixed;
// 	transition: 0.3s;
// 	transform: scale(0, 0);
// 	width: 100%;
// 	height: 100vh;
// 	z-index: 1000;
// 	left: 0;
// 	top: 0px;
// 	&.active {
// 		transform: scale(1, 1);
// 		backdrop-filter: blur(4px);
// 	}
// `;

// const BackContainer = styled.div``;
// const Overlay = styled.div`
// 	position: fixed;
// 	left: 0;
// 	top: 0px;
// 	width: 100%;
// 	min-height: 100vh;
// 	max-height: 100vh;
// 	filter: blur(1px);
// 	transform: scale(0);
// `;
// const Modal = styled.div`
// 	width: 90%;
// 	max-width: 640px;
// 	max-height: 100vh;
// 	position: absolute;
// 	margin: 0 auto;
// 	left: 50%;
// 	top: 50%;
// 	transform: translate(-50%, -50%);
// 	transition: 0.5s;
// 	z-index: 1000;
// 	background: #1b1c1f;
// 	border-radius: 5px;
// 	overflow-y: hidden;
// 	box-shadow: 0px 3px 56px #00000048;
// 	overflow-y: scroll;
// 	&::-webkit-scrollbar {
// 		display: none;
// 	}

// 	@media all and (max-width: 1280px) {
// 	}
// 	@media all and (max-width: 400px) {
// 		max-height: 70vh;
// 		overflow-y: scroll;
// 		&::-webkit-scrollbar {
// 			display: none;
// 		}
// 	}
// `;
// const Container = styled.div`
// 	position: relative;
// 	display: flex;
// 	flex-direction: column;
// 	padding: 40px 30px;
// 	width: 100%;
// 	height: 100%;
// 	min-width: 100%;
// 	max-height: 95vh;
// 	@media all and (max-width: 768px) {
// 		padding: 30px;
// 	}
// 	@media all and (max-width: 480px) {
// 		max-width: 430px;
// 		overflow-x: scroll;
// 		&::-webkit-scrollbar {
// 			display: none;
// 		}
// 	}
// 	@media all and (max-width: 360px) {
// 		padding: 10px;
// 	}
// 	span {
// 		color: #0fa76f;
// 		display: block;
// 		font-family: "Inter-Medium";
// 		margin-bottom: 25px;
// 	}
// `;

// const Head = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	margin-bottom: 10px;
// `;
// const Headtitle = styled.p`
// 	font-size: 18px;
// 	color: #ffffff;
// 	font-family: "gordita_medium";
// `;
// const CloseIcon = styled.div`
// 	width: 22px;
// 	cursor: pointer;
// 	img {
// 		width: 100%;
// 		display: block;
// 	}
// `;

// const AddExaminers = styled.div``;
// const InputField = styled.div`
// 	margin-bottom: 20px !important;
// 	background: #161619;
// 	border-radius: 5px;
// 	margin-left: 0;
// 	width: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	padding-right: 15px;
// 	position: relative;
// 	font-family: "gordita_regular";

// 	border: 1px solid #333333;
// 	&.error {
// 		border: 1px solid red;
// 	}
// 	select {
// 		width: 100%;
// 		padding: 15px 10px;
// 		color: #969696;
// 		font-family: "Inter-Medium";
// 	}
// 	input {
// 		width: 100%;
// 		padding: 15px 10px;
// 		color: #969696;
// 		font-family: "Inter-Medium";
// 		font-size: 14px;
// 	}
// 	transition: 3s ease;
// 	option {
// 		background: #161619;
// 		color: #fff;
// 		font-size: 14px;
// 	}
// `;
// const ImageContainer = styled.div`
// 	width: 21px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	img {
// 		width: 100%;
// 		display: block;
// 	}
// `;
// const DropDown = styled.div`
// 	z-index: 100;
// 	position: absolute;
// 	width: 100%;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: flex-start;
// 	border: 1px solid #26272a;
// 	border-radius: 5px;
// 	bottom: 50px;
// 	background: #1b1c1f;
// 	left: 0;
// 	top: 100%;
// 	color: #fff;
// 	transition: 3s ease;
// 	overflow: scroll;
// 	min-height: 130px;
// 	::-webkit-scrollbar {
// 		display: none;
// 	}
// 	&.question {
// 		min-height: 210px;
// 	}
// 	span {
// 		cursor: pointer;
// 		text-align: left;
// 		width: 100%;
// 		padding: 10px;
// 		font-size: 14px;
// 		color: #fff;
// 		background: #161619;
// 		text-transform: capitalize;
// 		:nth-child(2n) {
// 			background: #1b1c1f;
// 		}
// 		display: flex;
// 		align-items: center;
// 		justify-content: space-between;
// 		p {
// 			font-size: 14px;
// 			color: #fff;
// 		}
// 	}
// `;
// const TickImage = styled.img`
// 	display: none;
// 	&.selected {
// 		display: block;
// 		width: 18px;
// 	}
// `;

// const SelectCampusInput = styled.textarea`
// 	background: #161619;
// 	border-radius: 5px !important;
// 	border: 1px solid #333333;
// 	width: 100%;
// 	padding: 10px;
// 	height: 100px;
// 	color: #969696;
// 	font-family: "gordita_regular";
// 	font-size: 14px;
// 	resize: none;
// 	&.adress {
// 		height: 22vh;
// 	}
// 	border: 1px solid #333333;
// 	&.error {
// 		border: 1px solid red;
// 	}
// 	@media all and (max-width: 480px) {
// 		padding: 15px 0px 15px 10px;
// 	}
// `;
// const CancelButton = styled.div`
// 	color: #fff;
// 	border-radius: 5px !important;
// 	width: 120px;
// 	height: 40px;
// 	justify-content: center;
// 	display: flex;
// 	font-size: 14px;
// 	align-items: center;
// 	background: #161619;
// 	cursor: pointer;
// 	font-family: "gordita_medium";
// 	margin-right: 20px;
// 	border: 1px solid #fe673a;
// `;
// const Label = styled.div`
// 	color: #c3c3c3;
// 	font-size: 14px;
// 	font-family: "gordita_medium";
// 	margin-bottom: 6px;
// `;
// const Servicesfields = styled.div`
// 	width: 100%;
// 	position: relative;
// 	margin-bottom: 8px;
// 	:last-child {
// 		margin-left: 20px;
// 	}
// 	@media all and (max-width: 480px) {
// 		width: 100%;
// 		:last-child {
// 			margin-left: 0px;
// 			margin-top: 20px;
// 		}
// 	}
// `;
// const CreateButtonBox = styled.div`
// 	display: flex;
// 	justify-content: flex-end;
// 	@media all and (max-width: 480px) {
// 		margin-top: 10px;
// 	}
// `;
// const CreateButton = styled.div`
// 	background: #fe673a;
// 	color: #fff;
// 	border-radius: 5px !important;
// 	width: 120px;
// 	height: 40px;
// 	justify-content: center;
// 	display: flex;
// 	font-size: 14px;
// 	align-items: center;
// 	cursor: pointer;
// 	font-family: "Inter-Medium";
// `;

// const CoverTab = styled.div`
// 	display: flex;
// 	font-family: "gordita_medium";
// 	justify-content: space-between;
// 	font-size: 14px;

// 	span {
// 		color: #ffff;
// 		display: inline-block;
// 		font-family: "gordita_medium";
// 		font-size: 16px;
// 	}
// `;
// const Tab = styled.div`
// 	color: #969696;
// 	span {
// 		color: #969696;
// 		font-size: 14px;
// 		color: #fe673a;
// 		font-size: 16px;
// 		font-family: "gordita_regular";
// 	}
// 	small {
// 		color: #969696;
// 		font-size: 14px;
// 		color: #fe673a;
// 		font-size: 16px;
// 		font-family: "gordita_regular";
// 	}
// `;

import { useState, useContext } from "react";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function EditQuestionModal({
	AddModal,
	setAddModal,
	addNewQuestion,
	question = "",
	questiondata = {},
	optionsA = "",
	optionsB = "",
	optionsC = "",
	optionsD = "",
	setOptionsA,
	setOptionsB,
	setOptionsC,
	setOptionsD,
	editQestionData,
	setEditQuestionData,
	correctOption = "",
	setCorrectOption,
	setQuestion,
}) {
	const { state } = useContext(Context);
	const access_token = state.user_data.access_token;

	const handleSave = () => {
		addNewQuestion({
			// name: title,
			// thumbnail: thumbnail,
			// order_id: orderNumber,
			// duration: video,
			// is_publish: publish, // Include the isActive state in the payload
		});
		setAddModal(false);
	};
	console.log(questiondata, "dfghjk");

	return (
		<BackContainer>
			<Cover className={AddModal ? "active" : ""}>
				<Overlay
					onClick={() => {
						setAddModal(false);
					}}
				></Overlay>
				<Modal>
					<Container>
						<Head
							onClick={() => {
								setAddModal(false);
							}}
						>
							<Headtitle>Add Question</Headtitle>
							<CloseIcon>
								<img
									src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
									alt="Icon"
								/>
							</CloseIcon>
						</Head>

						<Servicesfields>
							<Label>Question</Label>

							<SelectCampusInput
								className={!question && "error"}
								placeholder="Enter your Question"
								value={questiondata.question || question}
								onChange={(e) => {
									setQuestion(e.target.value);
								}}
							/>
						</Servicesfields>
						<AddExaminers>
							<Label>Option A</Label>
							<InputField className={!optionsA && "error"}>
								<input
									placeholder="Enter option A"
									type="text"
									value={questiondata.option1 || optionsA}
									onChange={(e) => {
										setOptionsA(e.target.value);
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<Label>Option B</Label>
							<InputField className={!optionsB && "error"}>
								<input
									placeholder="Enter option B"
									type="text"
									value={questiondata.option2 || optionsB}
									onChange={(e) => {
										setOptionsB(e.target.value);
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<Label>Option C</Label>
							<InputField className={!optionsC && "error"}>
								<input
									placeholder="Enter option C"
									type="text"
									value={questiondata.option3 || optionsC}
									onChange={(e) => {
										setOptionsC(e.target.value);
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<Label>Option D</Label>
							<InputField className={!optionsD && "error"}>
								<input
									placeholder="Enter option D"
									type="text"
									value={questiondata.option4 || optionsD}
									onChange={(e) => {
										setOptionsD(e.target.value);
									}}
								/>
							</InputField>
						</AddExaminers>
						<AddExaminers>
							<Label>Correct Answer</Label>
							<InputField className={!correctOption && "error"}>
								<select
									value={correctOption}
									onChange={(e) =>
										setCorrectOption(e.target.value)
									}
								>
									<option value="" disabled>
										Select Right Answer
									</option>
									<option value="option1">A</option>
									<option value="option2">B</option>
									<option value="option3">C</option>
									<option value="option4">D</option>
								</select>
							</InputField>
						</AddExaminers>
						{console.log(
							questiondata.right_option,
							"questiondata.right_option"
						)}
						<CreateButtonBox>
							<CreateButton
								onClick={() => {
									if (
										optionsA &&
										optionsB &&
										optionsC &&
										optionsD &&
										(questiondata.right_option ||
											correctOption)
									)
										handleSave();
									setAddModal(false);
								}}
							>
								Submit
							</CreateButton>
						</CreateButtonBox>
					</Container>
				</Modal>
			</Cover>
		</BackContainer>
	);
}

export default EditQuestionModal;

const Cover = styled.div`
	position: fixed;
	transition: 0.3s;
	transform: scale(0, 0);
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	&.active {
		transform: scale(1, 1);
		backdrop-filter: blur(4px);
	}
`;

const BackContainer = styled.div``;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
	transform: scale(0);
`;
const Modal = styled.div`
	width: 90%;
	max-width: 640px;
	max-height: 100vh;
	position: absolute;
	margin: 0 auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: 0.5s;
	z-index: 1000;
	background: #1b1c1f;
	border-radius: 5px;
	overflow-y: hidden;
	box-shadow: 0px 3px 56px #00000048;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}

	@media all and (max-width: 1280px) {
	}
	@media all and (max-width: 400px) {
		max-height: 70vh;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
`;
const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 40px 30px;
	width: 100%;
	height: 100%;
	min-width: 100%;
	max-height: 95vh;
	@media all and (max-width: 768px) {
		padding: 30px;
	}
	@media all and (max-width: 480px) {
		max-width: 430px;
		overflow-x: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	@media all and (max-width: 360px) {
		padding: 10px;
	}
	span {
		color: #0fa76f;
		display: block;
		font-family: "Inter-Medium";
		margin-bottom: 25px;
	}
`;

const Head = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;
const Headtitle = styled.p`
	font-size: 18px;
	color: #ffffff;
	font-family: "gordita_medium";
`;
const CloseIcon = styled.div`
	width: 22px;
	cursor: pointer;
	img {
		width: 100%;
		display: block;
	}
`;

const AddExaminers = styled.div``;
const InputField = styled.div`
	margin-bottom: 20px !important;
	background: #161619;
	border-radius: 5px;
	margin-left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 15px;
	position: relative;
	font-family: "gordita_regular";

	border: 1px solid #333333;
	&.error {
		border: 1px solid red;
	}
	select {
		width: 100%;
		padding: 15px 10px;
		color: #969696;
		font-family: "Inter-Medium";
	}
	input {
		width: 100%;
		padding: 15px 10px;
		color: #969696;
		font-family: "Inter-Medium";
		font-size: 14px;
	}
	transition: 3s ease;
	option {
		background: #161619;
		color: #fff;
		font-size: 14px;
	}
`;
const ImageContainer = styled.div`
	width: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 100%;
		display: block;
	}
`;
const DropDown = styled.div`
	z-index: 100;
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid #26272a;
	border-radius: 5px;
	bottom: 50px;
	background: #1b1c1f;
	left: 0;
	top: 100%;
	color: #fff;
	transition: 3s ease;
	overflow: scroll;
	min-height: 130px;
	::-webkit-scrollbar {
		display: none;
	}
	&.question {
		min-height: 210px;
	}
	span {
		cursor: pointer;
		text-align: left;
		width: 100%;
		padding: 10px;
		font-size: 14px;
		color: #fff;
		background: #161619;
		text-transform: capitalize;
		:nth-child(2n) {
			background: #1b1c1f;
		}
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			font-size: 14px;
			color: #fff;
		}
	}
`;
const TickImage = styled.img`
	display: none;
	&.selected {
		display: block;
		width: 18px;
	}
`;

const SelectCampusInput = styled.textarea`
	background: #161619;
	border-radius: 5px !important;
	border: 1px solid #333333;
	width: 100%;
	padding: 10px;
	height: 100px;
	color: #969696;
	font-family: "gordita_regular";
	font-size: 14px;
	resize: none;
	&.adress {
		height: 22vh;
	}
	border: 1px solid #333333;
	&.error {
		border: 1px solid red;
	}
	@media all and (max-width: 480px) {
		padding: 15px 0px 15px 10px;
	}
`;
const CancelButton = styled.div`
	color: #fff;
	border-radius: 5px !important;
	width: 120px;
	height: 40px;
	justify-content: center;
	display: flex;
	font-size: 14px;
	align-items: center;
	background: #161619;
	cursor: pointer;
	font-family: "gordita_medium";
	margin-right: 20px;
	border: 1px solid #fe673a;
`;
const Label = styled.div`
	color: #c3c3c3;
	font-size: 14px;
	font-family: "gordita_medium";
	margin-bottom: 6px;
`;
const Servicesfields = styled.div`
	width: 100%;
	position: relative;
	margin-bottom: 8px;
	:last-child {
		margin-left: 20px;
	}
	@media all and (max-width: 480px) {
		width: 100%;
		:last-child {
			margin-left: 0px;
			margin-top: 20px;
		}
	}
`;
const CreateButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	@media all and (max-width: 480px) {
		margin-top: 10px;
	}
`;
const CreateButton = styled.div`
	background: #fe673a;
	color: #fff;
	border-radius: 5px !important;
	width: 120px;
	height: 40px;
	justify-content: center;
	display: flex;
	font-size: 14px;
	align-items: center;
	cursor: pointer;
	font-family: "Inter-Medium";
`;

const CoverTab = styled.div`
	display: flex;
	font-family: "gordita_medium";
	justify-content: space-between;
	font-size: 14px;

	span {
		color: #ffff;
		display: inline-block;
		font-family: "gordita_medium";
		font-size: 16px;
	}
`;
const Tab = styled.div`
	color: #969696;
	span {
		color: #969696;
		font-size: 14px;
		color: #fe673a;
		font-size: 16px;
		font-family: "gordita_regular";
	}
	small {
		color: #969696;
		font-size: 14px;
		color: #fe673a;
		font-size: 16px;
		font-family: "gordita_regular";
	}
`;
