import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import SectionLoader from "../../helpers/SectionLoader";
import { Link } from "react-router-dom";
import { yiaaiConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";

function CampusCatagory({ setCampusModal, campusModal }) {
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    // ----------(send campus details)---------//

    return (
        <BackContainer style={{ transform: campusModal && "scale(1,1)" }}>
            <Overlay
                onClick={() => setCampusModal(false)}
                style={{ transform: campusModal && "scale(1,1)" }}
            ></Overlay>
            <Modal data-effect="mfp-zoom-in">
                <Conatiner>
                    <HeadingContainer>
                        <Heading>Choose category</Heading>
                        <Close>
                            <ButtonClose onClick={() => setCampusModal(false)}>
                                <img
                                    src={
                                        require("../../../assets/images/close.svg")
                                            .default
                                    }
                                />
                            </ButtonClose>
                        </Close>
                    </HeadingContainer>
                    <BottomContainer>
                        <Button to={"/addschool/"}>School</Button>
                        <Button to={"/addcollege/"}>College</Button>
                        <Button to={"/addothers/"}>Other</Button>
                    </BottomContainer>
                </Conatiner>
            </Modal>
        </BackContainer>
    );
}

export default CampusCatagory;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.5s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
    transform: scale(0);
`;
const Modal = styled.div`
    // width: 90%;
    width: 550px;
    max-width: 550px;
    // height: 52vh;
    height: ${({ textarea }) => (textarea ? "90vh" : "")};
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #3b3b3b;
    background: #1b1c20;
    border-radius: 5px;
    // overflow-y: scroll;
    -webkit-scrollbar {
        display: none;
    }
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 980px) {
        width: 550px;
    }
    @media all and (max-width: 768px) {
        width: 400px;
    }
    @media all and (max-width: 640px) {
        width: 400px;
    }
    @media all and (max-width: 480px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const Conatiner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;

    @media all and (max-width: 640px) {
        padding: 28px;
    }
`;
const HeadingContainer = styled.div`
    border-bottom: 1px solid #3b3b3b;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Heading = styled.h3`
    font-family: "gordita_medium";
    font-size: 18px;
    color: #fff;
`;
const Close = styled.div`
    cursor: pointer;
`;
const ButtonClose = styled.div`
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const MiddleContainer = styled.div`
    // padding: 20px 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 55px auto;
    p {
        color: #fff;
        font-family: "gordita_regular";
        font-size: 16px;
    }
`;
const TopMiddle = styled.div``;
const ClassDiv = styled.div`
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-rows: 50px 50px 50px;
    grid-gap: 25px;
    @media all and (max-width: 360px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Span = styled.span`
    margin-right: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
`;
const ClassHeading = styled.p`
    font-size: 14px;
    font-family: "gordita_regular";
    margin-bottom: 10px;
`;
const InputDiv = styled.div`
    height: 25px;
    width: 25px;
    border: 1px solid #3b3b3b;
    margin-right: 5px;
    border-radius: 5px;
    // cursor: pointer;
    background: #161619;
    cursor: ${({ is_added }) => (is_added ? "not-allowed" : "pointer")};
    & div {
        display: none;

        img {
            width: 100%;
            display: block;
        }
    }
    & div.selected {
        display: block;
    }
`;
const BottomContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    @media all and (max-width: 480px) {
        justify-content: center;
    }
`;
const Button = styled(Link)`
    width: 30%;
    height: 50px;
    border: 1px solid #fe673a;
    border-radius: 5px;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: "gordita_regular";
    color: #fff;
    :hover {
        background: #fe673a;
        color: #fff;
    }
    @media all and (max-width: 480px) {
        width: 40%;
        height: 40px;
    }
`;
