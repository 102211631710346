import { useContext, useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { yiaaiConfig } from "../../../../axiosConfig";

import { Context } from "../../../contexts/Store";
import SectionLoader from "../../../genaral/Loader/RouterLoading";
import EditQuestionModal from "../../../includes/modals/EditQuestionModal";
import DeleteQuestion from "../../../includes/modals/DeleteQuestion";

function ListItem({
	setDeleteModal,
	isDeleteModal,
	isEditModal,
	category,
	subject,
	questions,
	setReload,
	reload,
	setQuestions,
	fetchAssessmentQuestion,
	setQusetions,
	assessmentDetail,
	isMalayalam,
	setQuestionObject,
	setQuestion,
}) {
	const [editModal, setEditModal] = useState(false);
	const [questionList, setQuestionList] = useState("");
	const [optionsA, setOptionsA] = useState("");
	const [optionsB, setOptionsB] = useState("");
	const [optionsC, setOptionsC] = useState("");
	const [optionsD, setOptionsD] = useState("");
	const [correctOption, setCorrectOption] = useState("");
	const [isDropDown, setDropDown] = useState(false);
	const [id, setId] = useState(0);
	const [isLoading, setLoading] = useState(false);
	const [pageLoading, setPageLoageing] = useState(false);
	const [isclassLoading, setClassLoading] = useState(false);
	const { state } = useContext(Context);
	const access_token = state.user_data.access_token;

	const wrapperRef = useRef(null);
	function useOutsideClick(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setDropDown(false);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}
	useOutsideClick(wrapperRef);
	console.log(questions, "questions#####");

	const [paginationData, setPaginationData] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currentQuestionId, setCurrentQuestionId] = useState(null);
	const currentParams = Object.fromEntries([...searchParams]);
	const [questionId, setQuestionID] = useState(0);
	const [questiondata, setQuestiondata] = useState("");

	const NextLabel = () => {
		return (
			<img
				src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/right-arrow.svg"
				alt="Prev Arrow"
			/>
		);
	};
	const PrevLabel = () => {
		return (
			<img
				src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-03-2023/left-arrow.svg"
				alt="Prev Arrow"
			/>
		);
	};

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage + 1);
		setSearchParams({
			...currentParams,
			page: selectedPage + 1,
		});
	};

	// const fetchquestionDetailSingle = () => {
	// 	setLoading(true);
	// 	yiaaiConfig
	// 		.get(`free-courses/assessment/question/${currentQuestionId}`, {
	// 			headers: {
	// 				Authorization: `Bearer ${access_token}`,
	// 			},
	// 			params: {
	// 				// page: currentPage,
	// 				// q: searchterm,
	// 			},
	// 		})
	// 		.then((response) => {
	// 			let { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setQuestiondata(data);
	// 			}
	// 			setLoading(false);
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			setLoading(false);
	// 		});
	// };
	// useEffect(() => {
	// 	fetchquestionDetailSingle();
	// }, [access_token]);
	console.log(currentQuestionId, "currentQuestionId");

	const EditNewQuestion = () => {
		setClassLoading(true);
		yiaaiConfig
			.post(
				`free-courses/edit-questions/${currentQuestionId}/`,
				{
					question: questionList,
					option1: optionsA,
					option2: optionsB,
					option3: optionsC,
					option4: optionsD,
					right_option: correctOption,
				},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				let { StatusCode } = response.data;
				if (StatusCode === 6000) {
					setClassLoading(false);
					setEditModal(false);
					setReload(!reload);
					setTimeout(() => {
						fetchAssessmentQuestion();
					}, 500);
				} else {
					setClassLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setClassLoading(false);
			});
	};
	const fetchquestionDetailSingle = () => {
		setLoading(true);
		yiaaiConfig
			.get(`free-courses/assessment/question/${currentQuestionId}`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
				params: {
					// page: currentPage,
					// q: searchterm,
				},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setQuestiondata(data);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (currentQuestionId) {
			fetchquestionDetailSingle(currentQuestionId);
		}
	}, [currentQuestionId]);
	const deleteQuestions = () => {
		if (!currentQuestionId) {
			console.log("No currentQuestionId provided");
			return;
		}
		setLoading(true);
		yiaaiConfig
			.post(
				`free-courses/assessment/delete-question/${currentQuestionId}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				let { StatusCode } = response.data;
				if (StatusCode === 6000) {
					setDeleteModal(false);
					// setTimeout(() => {
					// 	fetchAssessmentQuestion();
					// }, 500);
				} else {
					console.log("Failed to delete question");
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	return (
		<MainContainer>
			<EditQuestionModal
				AddModal={editModal}
				setAddModal={setEditModal}
				addNewQuestion={EditNewQuestion}
				setPageLoageing={setPageLoageing}
				question={questionList}
				optionsA={optionsA}
				optionsB={optionsB}
				optionsC={optionsC}
				optionsD={optionsD}
				setOptionsA={setOptionsA}
				setOptionsB={setOptionsB}
				setOptionsC={setOptionsC}
				setOptionsD={setOptionsD}
				correctOption={correctOption}
				setCorrectOption={setCorrectOption}
				setQuestion={setQuestionList}
				questiondata={questiondata}
				// editQestionData={editQestionData}
				// setEditQuestionData={setEditQuestionData}
			/>
			<DeleteQuestion
				isDeleteModal={isDeleteModal}
				setDeleteModal={setDeleteModal}
				questionId={questionId}
				DeleteQuestions={deleteQuestions}
				setPageLoageing={setPageLoageing}
				fetchAssessmentQuestion={fetchAssessmentQuestion}
			/>
			{isLoading ? (
				<EmptyBox>
					<SectionLoader />
				</EmptyBox>
			) : questions?.length > 0 ? (
				questions?.map((question, index) => (
					<Container key={index}>
						<Head>
							{paginationData?.first_item <=
								paginationData?.last_item && (
								<Question type="no">
									Question{" "}
									{paginationData?.first_item + index}
								</Question>
							)}
							<QuestionHeading>
								Question <span>{question.order_id}</span>{" "}
							</QuestionHeading>
							<DotButton
								onClick={() => {
									setDropDown(!isDropDown);
									setId(question.id);
								}}
							>
								<img
									src={
										require("../../../../assets/icons/three-dot.svg")
											.default
									}
									alt="Dots"
								/>
							</DotButton>
							<DropDow
								className={
									isDropDown && question.id === id
										? "active"
										: ""
								}
							>
								<List
									onClick={() => {
										// setEditModal(!isEditModal);
										setEditModal(true);
										setQuestionObject(question);
										setCurrentQuestionId(question.id);
										// fetchquestionDetailSingle();
									}}
								>
									Edit Question
								</List>
								<hr />
								<List
									onClick={() => {
										setDeleteModal(true);
										setCurrentQuestionId(question?.id);
									}}
								>
									Delete Question
								</List>
							</DropDow>
						</Head>

						<Para>
							{/* {isMalayalam
								? question?.malayalam.question
									? question?.malayalam.question
									: "--"
								: question?.english.question
								? question?.english.question
								: "--"} */}
							{question?.question ? question?.question : ""}
						</Para>
						<Cover>
							<Option
								className={
									question?.right_option === "option1"
										? "active"
										: ""
								}
							>
								<Box
									className={
										question?.right_option === "option1"
											? "active"
											: ""
									}
								>
									A
								</Box>
								<Span
									className={
										question?.right_option === "option1"
											? "active"
											: ""
									}
								>
									{question?.option1
										? question?.option1
										: "--"}
								</Span>
							</Option>
							<Option
								className={
									question?.right_option === "option2"
										? "active"
										: ""
								}
							>
								<Box
									className={
										question?.right_option === "option2"
											? "active"
											: ""
									}
								>
									B
								</Box>
								<Span
									className={
										question?.right_option === "option2"
											? "active"
											: ""
									}
								>
									{question?.option2
										? question?.option2
										: "--"}
								</Span>
							</Option>
							<Option
								className={
									question?.right_option === "option3"
										? "active"
										: ""
								}
							>
								<Box
									className={
										question?.right_option === "option3"
											? "active"
											: ""
									}
								>
									C
								</Box>
								<Span
									className={
										question?.right_option === "option3"
											? "active"
											: ""
									}
								>
									{question?.option3
										? question?.option3
										: "--"}
								</Span>
							</Option>
							<Option
								className={
									question?.right_option === "option4"
										? "active"
										: ""
								}
							>
								<Box
									className={
										question?.right_option === "option4"
											? "active"
											: ""
									}
								>
									D
								</Box>
								<Span
									className={
										question?.right_option === "option4"
											? "active"
											: ""
									}
								>
									{question?.option4
										? question?.option4
										: "--"}
								</Span>
							</Option>
						</Cover>
					</Container>
				))
			) : (
				<EmptyBox>No Data Found!</EmptyBox>
			)}
			{paginationData.total_pages > 1 && questions.length > 0 && (
				<PaginationDiv>
					<PageCount>
						{paginationData.last_item} of{" "}
						{paginationData.total_items} Results
					</PageCount>
					<PageLink>
						<ReactPaginate
							forcePage={currentPage - 1}
							nextLabel={<NextLabel />}
							previousLabel={<PrevLabel />}
							pageRangeDisplayed={1}
							onPageChange={handlePageClick}
							className="pagination"
							pageClassName="pagination__li"
							activeClassName="pagination__li__active"
							breakClassName="pagination__break__li"
							breakLinkClassName="pagination__break__a"
							previousClassName="pagination__previous__li"
							previousLinkClassName="pagination__previous__a"
							nextClassName="pagination__next__li"
							nextLinkClassName="pagination__next__a"
							disabledClassName="pagination_disabled_btns"
							pageCount={paginationData.total_pages}
						/>
					</PageLink>
				</PaginationDiv>
			)}
		</MainContainer>
	);
}

export default ListItem;

const MainContainer = styled.div``;
const Container = styled.div`
	background-color: #161619;
	padding: 24px;
	margin-bottom: 20px;
	border: 1px solid #3d3d3d;
	border-radius: 8px;
	@media all and (max-width: 480px) {
		margin-bottom: 10px;
		padding: 14px;
	}
	:last-child {
		margin-bottom: 0;
	}
`;
const Head = styled.div`
	color: #686868;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
`;

const QuestionHeading = styled.h5`
	color: #c9c9c9;
	font-size: 18px;
	font-family: "gordita_medium";
`;
const Question = styled.div`
	font-size: 16px;
	margin-bottom: 20px;
	font-family: "Inter-Medium";
	@media all and (max-width: 480px) {
		font-size: 15px;
	}
`;
const DotButton = styled.div`
	width: 20px;
	border-radius: 6px;
	padding: 8px;
	cursor: pointer;
	@media all and (max-width: 1280px) {
		padding: 3px;
	}
	@media all and (max-width: 980px) {
		width: 4%;
	}
	@media all and (max-width: 768px) {
		width: 5%;
	}
	@media all and (max-width: 640px) {
		width: 6%;
	}
	@media all and (max-width: 480px) {
		width: 8%;
	}
	img {
		width: 100%;
		display: block;
	}
`;
const Para = styled.p`
	font-size: 16px;
	color: #ffffff;
	font-family: "gordita_medium";
	margin-bottom: 20px;
	@media all and (max-width: 480px) {
		font-size: 15px;
	}
`;
const Cover = styled.div``;
const Option = styled.div`
	border: 1px solid #3d3d3d;
	border-radius: 8px;
	margin-bottom: 10px;
	padding: 12px;
	display: flex;
	background-color: #1b1c1f;
	align-items: center;
	justify-content: flex-start;
	font-family: "Inter-Medium";
	color: #686868;
	font-size: 14px;
	cursor: pointer;
	@media all and (max-width: 480px) {
		font-size: 13px;
	}

	:last-child {
		margin-bottom: 0;
	}
	&.active {
		border: 1px solid #fe673a;
	}
`;
const Box = styled.div`
	margin-right: 10px;
	border: 1px solid #3d3d3d;
	border-radius: 3px;
	padding: 4px 8px;
	background: #161619;
	color: #ffffff;
	font-family: "gordita_medium";

	&.active {
		color: #fe673a;
		background: #161619;
	}
`;
const Span = styled.span`
	display: inline-block;
	color: #ffffff;
	font-family: "gordita_regular";
	&.active {
		color: #fe673a;
	}
`;
const DropDow = styled.div`
	background: #1b1c1f;
	border: 1px solid #3d3d3d;
	box-shadow: 0px 2.89351px 7.7px rgba(0, 0, 0, 0.14);
	border-radius: 6px;
	padding: 4px 18px;
	position: absolute;
	right: 0%;
	top: 100%;
	display: none;
	&.active {
		display: block;
	}
`;
const List = styled.div`
	padding: 10px 0;
	font-family: "gordita_regular";
	border-bottom: 1px solid #3d3d3d;
	font-size: 14px;
	cursor: pointer;
	:last-child {
		border-bottom: 0;
	}
	:hover {
		color: #fe673a;
	}
`;
const EmptyBox = styled.div`
	min-height: 400px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-family: "Inter-Medium";
	border: 1px solid #3d3d3d;
	border-radius: 8px;
	background-color: #1b1c1f;
`;
const PaginationDiv = styled.div`
	height: 58px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
`;
const PageLink = styled.div``;
const PageCount = styled.p`
	font-size: 14px;
	font-family: "Inter-Medium";
	color: #aaa;
`;
