import React, { useState, useContext, useEffect } from "react";
import { format } from "date-fns";
import styled from "styled-components";
import { yiaaiConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import SectionLoader from "../../../helpers/SectionLoader";
import DigitalEnrollmentCard from "../../../includes/yiaai/EntrollmentCard";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import queryString from "query-string";
import YiaaiAdminHelmet from "../../../helpers/YiaaiAdminHelmet";
import { Link, useParams, useSearchParams } from "react-router-dom";
import FilterModal from "../../../includes/modals/FilterModal";

function DigitalEnrollments({ campusCount }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isLoading, setLoading] = useState(false);
	const [entrollments, setEntrollments] = useState([]);
	const [entrollmentDetails, setEntrollmentDetails] = useState("");
	const [isCountLoading, setIsCountLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchterm, setSearchTerm] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [filters, setFilters] = useState({
		startDate: null,
		onProgress: false,
		completed: false,
	});
	const currentParams = Object.fromEntries([...searchParams]);
	const [pagination, setPagination] = useState({});
	const { slug } = useParams();
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);
	const location = useLocation({ isCountLoading });
	let { search } = location;
	const values = queryString.parse(search);
	let page = values?.page ? Number(values.page) : 1;

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleApplyFilter = (startDate, onProgress, completed) => {
		setFilters({ startDate, onProgress, completed });
		setShowModal(false);
	};

	const handlePageClick = ({ selected: selectedPage }) => {
		const newPage = selectedPage + 1;
		setCurrentPage(newPage);
		setSearchParams({
			...currentParams,
			page: newPage.toString(),
		});
	};
	console.log(filters.startDate, "5ttt");
	useEffect(() => {
		const setInitialSearch = () => {
			setCurrentPage(page ? parseInt(page) : 1);
		};
		if (page) {
			setInitialSearch();
		}
	}, [page]);

	const fetchEntrollments = () => {
		setLoading(true);

		const formattedStartDate = filters.startDate
			? format(new Date(filters.startDate), "yyyy-MM-dd")
			: null;

		yiaaiConfig
			.get(`free-courses/students/${slug}`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
				params: {
					page: page,
					q: searchterm,
					date: formattedStartDate,
					is_completed: filters.completed
						? true
						: filters.onProgress
						? false
						: null,
				},
			})
			.then((response) => {
				let { StatusCode, paginator_data, data } = response.data;
				if (StatusCode === 6000) {
					setEntrollments(data);
					setPagination(paginator_data);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};
	useEffect(() => {
		fetchEntrollments();
	}, [access_token, page, searchterm, slug, filters]);

	useEffect(() => {
		const fetchEntrollmentDetails = () => {
			setLoading(true);
			yiaaiConfig
				.get(`campuses/total-students-count/${slug}`, {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
					params: {
						page: page,
						q: searchterm,
					},
				})
				.then((response) => {
					let { StatusCode, paginator_data, data } = response.data;
					if (StatusCode === 6000) {
						setEntrollmentDetails(data);
						setPagination(paginator_data);
					}
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchEntrollmentDetails();
	}, [access_token, page, searchterm, slug]);

	const name = searchParams.get("name");
	console.log(page, "currntpage");

	return (
		<>
			<YiaaiAdminHelmet title="Lessons" />
			<MainContainer>
				<Container>
					<TopContainer>
						<Card>
							<LabelContainer>
								<ImageContainerSmall>
									<Logo
										src={
											require("../../../../assets/icons/students.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainerSmall>
								<Description>
									<h3>Total Students</h3>
								</Description>
							</LabelContainer>
							<Count>{entrollmentDetails.total_students}</Count>
						</Card>

						<Card>
							<LabelContainer>
								<ImageContainerSmall>
									<Logo
										src={
											require("../../../../assets/icons/completed-students.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainerSmall>
								<Description>
									<h3>Completed Students</h3>
								</Description>
							</LabelContainer>
							<Count>{entrollmentDetails.course_completed}</Count>
						</Card>

						<Card>
							<LabelContainer>
								<ImageContainerSmall>
									<Logo
										src={
											require("../../../../assets/icons/incomplete-students.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainerSmall>
								<Description>
									<h3>Incomplete Students</h3>
								</Description>
							</LabelContainer>
							<Count>
								{entrollmentDetails.incomplete_students}
							</Count>
						</Card>

						<Card>
							<LabelContainer>
								<ImageContainerSmall>
									<Logo
										src={
											require("../../../../assets/icons/active-students.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainerSmall>
								<Description>
									<h3>Active Students</h3>
								</Description>
							</LabelContainer>
							<Count>{entrollmentDetails.active_users}</Count>
						</Card>
					</TopContainer>
					<BoxContainer>
						<Cover>
							<Head>
								<Heading>Lessons</Heading>
							</Head>
							<CoverContainer>
								<InputContainer>
									<SearchName
										type="text"
										placeholder="Search Here"
										onChange={(e) => {
											setSearchTerm(e.target.value);
										}}
										value={searchterm}
									/>
									<SearchImage to={`?query=${searchterm}`}>
										<img
											src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg"
											alt="image"
										/>
									</SearchImage>
								</InputContainer>
								<FilterDiv>
									<ButtonDiv
										onClick={() => {
											setShowModal(!showModal);
										}}
									>
										<span>Filter</span>
										<ButtonIcon>
											<img
												src={
													require("../../../../assets/icons/filter-lines.svg")
														.default
												}
											/>
										</ButtonIcon>
									</ButtonDiv>
									{showModal && (
										<FilterModal
											showModal={showModal}
											setShowModal={setShowModal}
											onClose={handleCloseModal}
											onApply={handleApplyFilter}
										/>
									)}
								</FilterDiv>
							</CoverContainer>
						</Cover>
						<TableCover>
							<TableHeading>
								<Items>
									<Label type="slno">Order No.</Label>
									<Label type="profile">Profile</Label>
									<Label type="date">
										Date of enrollment
									</Label>
									<Label type="topic">Current topic</Label>
									<Label type="action"></Label>
								</Items>
							</TableHeading>
							{isLoading ? (
								<SectionLoader />
							) : (
								<>
									{entrollments.length > 0 ? (
										<UserCardCover>
											{entrollments.map((item, index) => (
												<DigitalEnrollmentCard
													key={index}
													index={index}
													entrollment={item}
													pagination={pagination}
												/>
											))}
										</UserCardCover>
									) : (
										<ImageBox>
											<CardImage
												src={
													"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/12-02-2022/emptycard.svg"
												}
												alt="Image"
											/>
										</ImageBox>
									)}
								</>
							)}
						</TableCover>
					</BoxContainer>
					{pagination && pagination.total_pages > 1 && (
						<PaginationContainer>
							<PaginationText>
								Showing lessons {pagination.first_item} -{" "}
								{pagination.last_item} of{" "}
								{pagination.total_items}
							</PaginationText>
							<ReactPaginate
								previousLabel={"< Prev"}
								nextLabel={"Next >"}
								pageCount={pagination.total_pages}
								onPageChange={handlePageClick}
								containerClassName={"pagination"}
								previousLinkClassName={"pagination__link"}
								nextLinkClassName={"pagination__link"}
								disabledClassName={"pagination__link--disabled"}
								activeClassName={"pagination__link--active"}
								forcePage={page - 1} // Ensure correct initial page
							/>
						</PaginationContainer>
					)}
				</Container>
			</MainContainer>
		</>
	);
}

export default DigitalEnrollments;

const MainContainer = styled.div`
	color: #fff;
`;
const Container = styled.div`
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const BoxContainer = styled.div`
	min-width: 1280px;
`;

const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 15px;
`;
const Heading = styled.h3`
	font-family: "gordita_medium";
	font-size: 20px;
`;
const TableCover = styled.div`
	background-color: #1b1c1f;
	padding: 20px 20px 0;
	border-radius: 5px;
	overflow-x: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
`;
const FilterDiv = styled.div`
	position: relative;
`;
const TableHeading = styled.div`
	min-width: 1200px;
`;
const Items = styled.ul`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
`;
const Label = styled.li`
	width: 10%;
	text-align: center;
	color: #b1b1b1;
	font-family: "gordita_medium";
	width: ${(props) =>
		props.type === "slno"
			? "9%"
			: props.type === "profile"
			? "30%"
			: props.type === "topic"
			? "20%"
			: props.type === "date"
			? "25%"
			: props.type === "action"
			? "10%"
			: "10%"};
	text-align: start;
	/* text-align: ${(props) =>
		props.type === "profile" ? "left" : "center"}; */
`;
const ImageBox = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px 0;
`;
const UserCardCover = styled.div`
	padding: 20px 0;
`;
const TopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
`;
const Logo = styled.img`
	width: 100%;
	display: block;
`;
const ImageContainerSmall = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* background: #fdd186; */
	border-radius: 50%;
`;
const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 0 15px;
	@media all and (max-width: 890px) {
		flex-direction: column;
	}
`;
const PaginationText = styled.p`
	color: #898989;
	font-size: 14px;
	@media all and (max-width: 890px) {
		margin-bottom: 10px;
	}
`;
const Card = styled.div`
	width: calc(25% - 15px);
	background-color: #1b1c1f;
	padding: 20px 10px;
	border-radius: 5px;
	/* display: flex;
	justify-content: space-between; */
`;
const Description = styled.div`
	margin-left: 10px;
	color: #ffffff;
	font-family: "gordita_medium";
	font-size: 16px;
`;
const LabelContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
`;
const Count = styled.div`
	display: flex;
	align-items: flex-end;
	color: #ffffff;
	font-family: "gordita_medium";
	font-size: 20px;
`;
const InputContainer = styled.div`
	width: 40%;
	position: relative;
	display: flex;
	justify-content: space-between;
	input {
		width: calc(100% - 10px);
		background-color: #191a1c;
		border: 0;
		border-radius: 5px;
		padding: 12px;
		font-size: 12px;
		font-family: "gordita_regular";
		color: #fff;
		&::placeholder {
			color: #686868;
		}
	}
`;
const CoverContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const SearchImage = styled(Link)`
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
`;
const Cover = styled.div`
	background-color: #1b1c1f;
	padding: 20px;
	border-radius: 5px;
	margin-bottom: 15px;
`;
const SearchName = styled.input``;
const ButtonDiv = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	span {
		font-family: "gordita_medium";
		font-size: 12px;
	}
`;
const ButtonIcon = styled.div`
	width: 16px;
	height: 16px;
	margin-left: 10px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
const CardImage = styled.img``;
